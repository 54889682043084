import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { IRegistrationImportLog, IRegistrationImportLogFilter } from '../types/registration-import-log.model';

export enum RegistrationImportLogActionTypes {
  GetRegistrationImportLogs = '[RegistrationImportLog] Get paged list',
  GetRegistrationImportLogsSuccess = '[RegistrationImportLog] Get paged success',
  GetRegistrationImportLogsFailed = '[RegistrationImportLog] Get paged failed',
}

export const getRegistrationImportLogs = createAction(
  RegistrationImportLogActionTypes.GetRegistrationImportLogs,
  props<{ filter: IRegistrationImportLogFilter }>(),
);

export const getRegistrationImportLogsSuccess = createAction(
  RegistrationImportLogActionTypes.GetRegistrationImportLogsSuccess,
  props<{ result: IPaginatedList<IRegistrationImportLog> }>(),
);

export const getRegistrationImportLogsFailed = createAction(RegistrationImportLogActionTypes.GetRegistrationImportLogsFailed);
