/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map, of, switchMap, take } from 'rxjs';
import { selectRolesForLoggedInUser, selectRolesLoaded } from '../../modules/users/reducers';

@Injectable()
export class MyAuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectRolesLoaded).pipe(
      take(1),
      switchMap(rolesLoaded => {
        if (rolesLoaded) {
          // Roles are already loaded, proceed with the guard logic.
          return this.checkRoles(route);
        } else {
          // Roles haven't been loaded yet. You might want to dispatch an action
          // to load the roles or handle this situation based on your specific requirements.
          // For now, you can simply allow access, and later re-check the roles when they're loaded.
          return of(true);
        }
      }),
    );
  }

  private checkRoles(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectRolesForLoggedInUser).pipe(
      map(roles => {
        if (roles.some(r => route.data['roles'].includes(r))) {
          return true;
        } else {
          this.router.navigateByUrl('/401');
          return false;
        }
      }),
    );
  }
}

