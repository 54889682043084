import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as EducationActions from '../actions/education.actions';
import { Education, EducationFilter } from '../types/educations/education.model';

export interface EducationState {
  educations: Education[];
  education: Education;
  overview: {
    filter: EducationFilter;
    pagination: Pagination;
  };
  isLoading: boolean;
}

const initialEducationState: EducationState = {
  educations: [],
  overview: {
    filter: {} as EducationFilter,
    pagination: {} as Pagination,
  },
  education: null,
  isLoading: false,
};

export const educationReducers = createReducer(
  initialEducationState,
  on(
    EducationActions.getEducations,
    (state, { filter }): EducationState => ({
      ...state,
      overview: { ...state.overview, filter: filter },
      isLoading: true,
    }),
  ),
  on(
    EducationActions.getEducationsSuccess,
    (state, { educations }): EducationState => ({
      ...state,
      isLoading: false,
      educations: educations.items,
      overview: {
        ...state.overview,
        pagination: extractPagination(educations),
      },
    }),
  ),
  on(
    EducationActions.getEducationsFailed,
    (state): EducationState => ({
      ...state,
      educations: [],

      isLoading: false,
    }),
  ),
  on(
    EducationActions.getEducation,
    (state): EducationState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    EducationActions.getEducationSuccess,
    (state, { education }): EducationState => ({
      ...state,
      isLoading: false,
      education: education,
    }),
  ),
  on(
    EducationActions.getEducationFailed,
    (state): EducationState => ({
      ...state,
      education: null,
      isLoading: false,
    }),
  ),
  on(
    EducationActions.createEducation,
    (state): EducationState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    EducationActions.createEducationSuccess,
    (state, { education }): EducationState => ({
      ...state,
      isLoading: false,
      education: education,
    }),
  ),
  on(
    EducationActions.createEducationFailed,
    (state): EducationState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    EducationActions.updateEducation,
    (state): EducationState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    EducationActions.updateEducationSuccess,
    (state, { education }): EducationState => ({
      ...state,
      isLoading: false,
      education: education,
    }),
  ),
  on(
    EducationActions.updateEducationFailed,
    (state): EducationState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    EducationActions.deleteEducationSuccess,
    (state): EducationState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    EducationActions.deleteEducationFailed,
    (state): EducationState => ({
      ...state,
      isLoading: false,
    }),
  ),
);

export const selectEducationsState = createSelector(
  (state: State) => state.educations,
  (state: EducationState) => state,
);

export const selectEducationsForOverView = createSelector(selectEducationsState, educationState => educationState.educations);
