import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { IStudentImportLog, IStudentImportLogFilter } from '../types/student-import-log.model';

export enum StudentImportLogActionTypes {
  GetStudentImportLogs = '[StudentImportLog] Get paged list',
  GetStudentImportLogsSuccess = '[StudentImportLog] Get paged success',
  GetStudentImportLogsFailed = '[StudentImportLog] Get paged failed',
}

export const getStudentImportLogs = createAction(
  StudentImportLogActionTypes.GetStudentImportLogs,
  props<{ filter: IStudentImportLogFilter }>(),
);

export const getStudentImportLogsSuccess = createAction(
  StudentImportLogActionTypes.GetStudentImportLogsSuccess,
  props<{ result: IPaginatedList<IStudentImportLog> }>(),
);

export const getStudentImportLogsFailed = createAction(StudentImportLogActionTypes.GetStudentImportLogsFailed);
