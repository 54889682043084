import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { IPaginatedList, Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as transactionActions from '../actions/transaction.actions';
import { ITransaction, ITransactionFilter } from '../types/transaction';

export interface TransactionState {
  selectedTransaction: ITransaction;
  transactions: IPaginatedList<ITransaction>;
  isLoading: boolean;
  overview: {
    filter: ITransactionFilter;
    pagination: Pagination;
  };
}

const initialTransactionState: TransactionState = {
  selectedTransaction: null,
  transactions: {} as IPaginatedList<ITransaction>,
  isLoading: false,
  overview: {
    filter: null as ITransactionFilter,
    pagination: null as Pagination,
  },
};

export const transactionReducers = createReducer(
  initialTransactionState,
  on(
    transactionActions.getTransactions,
    (state, { filter }): TransactionState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    transactionActions.getTransactionsSuccess,
    (state, { result }): TransactionState => ({
      ...state,
      isLoading: false,
      transactions: result,
      overview: {
        ...state.overview,
        pagination: extractPagination(result),
      },
    }),
  ),
  on(
    transactionActions.getTransactionsFailed,
    (state): TransactionState => ({
      ...state,
      isLoading: false,
      transactions: {} as IPaginatedList<ITransaction>,
      overview: {
        pagination: null as Pagination,
        filter: null as ITransactionFilter,
      },
    }),
  ),
  on(
    transactionActions.getTransaction,
    (state, { transactionId }): TransactionState => ({
      ...state,
      selectedTransaction: null,
    }),
  ),
  on(
    transactionActions.getTransactionSuccess,
    (state, { transaction }): TransactionState => ({
      ...state,
      selectedTransaction: transaction,
    }),
  ),
  on(
    transactionActions.getTransactionFailed,
    (state): TransactionState => ({
      ...state,
      selectedTransaction: null,
    }),
  ),
);

export const selectTransactionsState = createSelector(
  (state: State) => state.transactions,
  (state: TransactionState) => state,
);

export const selectTransactionsForOverView = createSelector(selectTransactionsState, (state: TransactionState) => {
  return { transactions: state.transactions };
});

export const selectPaginationForTransactionsOverview = createSelector(
  selectTransactionsState,
  transactionsState => transactionsState.overview.pagination,
);

export const selectTransaction = createSelector(
  selectTransactionsState,
  transactionState => transactionState.selectedTransaction,
);
