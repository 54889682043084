import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { ITransaction, ITransactionFilter } from '../types/transaction';

export enum TransactionActionTypes {
  GetTransactions = '[Transaction] Get paged list',
  GetTransactionsSuccess = '[Transaction] Get paged success',
  GetTransactionsFailed = '[Transaction] Get paged failed',
  GetTransaction = '[Transaction] Get',
  GetTransactionSuccess = '[Transaction] Get success',
  GetTransactionFailed = '[Transaction] Get failed',
}

export const getTransactions = createAction(TransactionActionTypes.GetTransactions, props<{ filter: ITransactionFilter }>());
export const getTransactionsSuccess = createAction(
  TransactionActionTypes.GetTransactionsSuccess,
  props<{ result: IPaginatedList<ITransaction> }>(),
);
export const getTransactionsFailed = createAction(TransactionActionTypes.GetTransactionsFailed);

export const getTransaction = createAction(TransactionActionTypes.GetTransaction, props<{ transactionId: string }>());
export const getTransactionSuccess = createAction(
  TransactionActionTypes.GetTransactionSuccess,
  props<{ transaction: ITransaction }>(),
);
export const getTransactionFailed = createAction(TransactionActionTypes.GetTransactionFailed);
