import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { IStudentFilter, Student } from '../types/student.model';

export enum StudentActionTypes {
  LoadStudents = '[Student] Loading students',
  LoadStudentsSucces = '[Student] Loading succes',
  LoadStudentsFailed = '[Student] Loading failed',

  GetStudent = '[Student] Get Student details',
  GetStudentSuccess = '[Student] Get details success',
  GetStudentFailed = '[Student] Get details failed',
}

export const getStudents = createAction(StudentActionTypes.LoadStudents, props<{ filter: IStudentFilter }>());
export const getStudentsSuccess = createAction(
  StudentActionTypes.LoadStudentsSucces,
  props<{ response: IPaginatedList<Student> }>(),
);
export const getStudentsFailure = createAction(StudentActionTypes.LoadStudentsFailed);

export const getStudent = createAction(StudentActionTypes.GetStudent, props<{ id: string }>());
export const getStudentSuccess = createAction(StudentActionTypes.GetStudentSuccess, props<{ response: Student }>());
export const getStudentFailure = createAction(StudentActionTypes.GetStudentFailed);
