import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { createAction, props } from '@ngrx/store';
import UIActions from './ui.actions.enum';
import { LoaderStyle } from '../../../types/loader-styles.enum';

export const openSnackbar = createAction(
  UIActions.SnackbarOpen,
  props<{ message: string; action?: string; config?: MatSnackBarConfig }>(),
);

export const closeSnackbar = createAction(UIActions.SnackbarClose);
export const ShowLoader = createAction(UIActions.ShowLoader, props<{ title: string; loaderStyle?: LoaderStyle }>());

export const HideLoader = createAction(UIActions.HideLoader);
