<ul *ngIf="(breadcrumbs$ | async)?.length ?? 0 > 0">
  <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; let i = index">
    <li class="breadcrumb-divider" *ngIf="i !== 0">></li>
    <li>
      <app-breadcrumb-item
        [label]="breadcrumb.label"
        [link]="breadcrumb.url"
        [exactUrl]="i !== ((breadcrumbs$ | async)?.length ?? 0) - 1"
      ></app-breadcrumb-item>
    </li>
  </ng-container>
</ul>
