import * as CombinationActions from '../actions/combinations.actions';
import { createReducer, on } from '@ngrx/store';

export interface Combination {
  id: string;
  name: string;
  defaultPrice?: number;
  pricePerCredit?: number;
  missingStudentCreditsPrice?: number;
  administrationPriceFee?: number;
  parameterId: string;
  parameterType: ParameterType;
  childCombinations?: Combination[];
}

export interface CombinationGetResult {
  items: Combination[];
}

export enum ParameterType {
  AcademicYear = 'AcademicYear',
  EducationType = 'EducationType',
  Scholarship = 'Scholarship',
  CountryType = 'CountryType',
  Category = 'Category',
  Contract = 'Contract',
  EducationGroup = 'EducationGroup',
  Education = 'Education',
}

export type CombinationState = {
  selectedCombination: Combination;
  combinations: Combination[];
  isLoading: boolean;
};

// export const combinationAdapter = createEntityAdapter<Combination>();

export const initialTreeState: CombinationState = {
  combinations: [],
  selectedCombination: null,
  isLoading: false,
};

export const combinationReducers = createReducer(
  initialTreeState,
  // on(CombinationActions.addCombination, (state, { node }) => {
  //   return combinationAdapter.addOne(node, state);
  // }),
  // on(CombinationActions.removeCombination, (state, { id }) => {
  //   return combinationAdapter.removeOne(id, state);
  // }),
  on(
    CombinationActions.getCombinationsSuccess,
    (state, { result }): CombinationState => ({
      ...state,
      combinations: result,
      isLoading: false,
    }),
  ),
);
