import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { provideEffects } from '@ngrx/effects';
import { MetaReducer, USER_PROVIDED_META_REDUCERS, provideStore } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app/app-routing.module';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './app/authentication/msal-authentication.config';
import { CustomDateAdapter, MATERIAL_DATEPICKER_FORMATS } from './app/common/adapters/custom-date-adapter';
import { UIEffects } from './app/common/effects/snackbar.effects';
import { AppComponent } from './app/components/app/app.component';
import { APP_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY, ROOT_STORAGE_KEYS } from './app/components/app/app.tokens';
import { BaseUrlInterceptor } from './app/interceptors/base-url.interceptor';
import { LanguageInterceptor } from './app/interceptors/language.interceptor';
import { environment } from './environments/environment';
import { COUNTRIES_STORAGE_KEYS } from './modules/internationalization/internationalization.tokens';
import {
  ACADEMIC_YEARS_PARAMETER_STORAGE_KEYS,
  CATEGORIES_PARAMETER_STORAGE_KEYS,
  EDUCATIONS_PARAMETER_STORAGE_KEYS,
  EDUCATION_GROUPS_PARAMETER_STORAGE_KEYS,
  EDUCATION_TYPES_PARAMETER_STORAGE_KEYS,
  PAYMENT_METHODS_PARAMETER_STORAGE_KEYS,
  SCHOLARSHIP_PARAMETER_STORAGE_KEYS,
} from './modules/parameters/parameters.tokens';
import { LocalStorageService } from './modules/shared/services/localstorage.service';
import { reducers } from './redux/reducers/index.reducer';
import { storageMetaReducer } from './redux/reducers/meta.reducer';

import { registerLocaleData } from '@angular/common';

import localeBE from '@angular/common/locales/be';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule } from '@angular/material-moment-adapter';
import { LOG_STORAGE_KEYS } from './modules/import-logs/log.tokens';
import { MyAuthGuard } from './app/authentication/auth.guard';
import { REGISTRATION_STORAGE_KEYS } from './modules/registrations/registration.tokens';
import { STUDENT_STORAGE_KEYS } from './modules/students/student.tokens';
import { TRANSACTION_STORAGE_KEYS } from './modules/transactions/transaction.tokens';
import { RoleListEffects } from './modules/users/effects/role-list.effect';
import { USER_STORAGE_KEYS } from './modules/users/user.tokens';
registerLocaleData(localeBE);

export function getMetaReducers(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService,
): MetaReducer<any>[] {
  return [storageMetaReducer(saveKeys, localStorageKey, storageService)];
}

// We can't implement provideHttpClient for Msal
bootstrapApplication(AppComponent, {
  providers: [
    provideStore(reducers),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-BE' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: false, hasBackdrop: true } },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: ROOT_STORAGE_KEYS,
      useValue: [
        ...APP_STORAGE_KEYS,
        ...ACADEMIC_YEARS_PARAMETER_STORAGE_KEYS,
        ...CATEGORIES_PARAMETER_STORAGE_KEYS,
        ...COUNTRIES_STORAGE_KEYS,
        ...EDUCATION_GROUPS_PARAMETER_STORAGE_KEYS,
        ...EDUCATION_TYPES_PARAMETER_STORAGE_KEYS,
        ...EDUCATIONS_PARAMETER_STORAGE_KEYS,
        ...PAYMENT_METHODS_PARAMETER_STORAGE_KEYS,
        ...SCHOLARSHIP_PARAMETER_STORAGE_KEYS,
        ...STUDENT_STORAGE_KEYS,
        ...REGISTRATION_STORAGE_KEYS,
        ...TRANSACTION_STORAGE_KEYS,
        ...USER_STORAGE_KEYS,
        ...LOG_STORAGE_KEYS,
      ],
    },
    { provide: ROOT_LOCAL_STORAGE_KEY, useValue: '__app_storage__' },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      deps: [ROOT_STORAGE_KEYS, ROOT_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getMetaReducers,
    },
    MsalService,
    MsalGuard,
    MyAuthGuard,
    MsalBroadcastService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideEffects([UIEffects, RoleListEffects]),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      MomentDateModule,
      AppRoutingModule,
      MsalModule,
      HttpClientModule,
      MatDialogModule,
      MatSnackBarModule,
      StoreDevtoolsModule.instrument({
        maxAge: 50,
        logOnly: environment.production,
      }), // Needed for SnackbarService
    ),
  ],
}).catch(err => console.error(err));
