import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from 'src/redux/reducers/index.reducer';
import { IPaginatedList, Pagination } from '../types/paginated.list.model';
import { extractPagination } from '../types/pagination-filter.model';
import { IUserFilter } from '../types/user-filter.model';
import User from '../types/user.model';
import * as userActions from './actions/users/user.actions';

export interface UserState {
  users: IPaginatedList<User>;
  isLoading: boolean;
  overview: {
    filter: IUserFilter;
    pagination: Pagination;
  };
}

const initialUserState: UserState = {
  users: {} as IPaginatedList<User>,
  isLoading: false,
  overview: {
    filter: null as IUserFilter,
    pagination: null as Pagination,
  },
};

export const userReducers = createReducer(
  initialUserState,
  on(
    userActions.getUsers,
    (state, { filter }): UserState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    userActions.getUsersSuccess,
    (state, { response }): UserState => ({
      ...state,
      isLoading: false,
      users: response,
      overview: {
        ...state.overview,
        pagination: extractPagination(response),
      },
    }),
  ),
  on(
    userActions.getUsersFailure,
    (state): UserState => ({
      ...state,
      isLoading: true,
      users: {} as IPaginatedList<User>,
      overview: {
        pagination: null as Pagination,
        filter: null as IUserFilter,
      },
    }),
  ),
  on(
    userActions.putUser,
    (state): UserState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    userActions.putUserSuccess,
    (state): UserState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    userActions.putUserFailure,
    (state): UserState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    userActions.postUser,
    (state): UserState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    userActions.postUserSuccess,
    (state): UserState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    userActions.postUserFailure,
    (state): UserState => ({
      ...state,
      isLoading: false,
    }),
  ),
);

export const selectUsersState = createSelector(
  (state: State) => state.users,
  (state: UserState) => state,
);

export const selectUsersForOverView = createSelector(selectUsersState, (state: UserState) => {
  return { users: state.users };
});

export const selectPaginationForUsersOverview = createSelector(selectUsersState, usersState => usersState.overview.pagination);
