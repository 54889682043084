import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { ICreateEducationGroup, IEducationGroup, IEducationGroupFilter, IUpdateEducationGroup } from '../types/education-group.model';

export enum EducationGroupActions {
  SelectEducationGroup = '[EducationGroup] Select education group',

  LoadEducationGroups = '[EducationGroup] Loading education groups',
  LoadEducationGroupsSuccess = '[EducationGroup] Loading education groups success!',
  LoadEducationGroupsFailed = '[EducationGroup] Loading education groups failed!',

  CreateEducationGroup = '[EducationGroup] Creating education group',
  CreateEducationGroupSuccess = '[EducationGroup] Creating success!',
  CreateEducationGroupFailed = '[EducationGroup] Creating failed!',

  UpdateEducationGroup = '[EducationGroup] Updating education group',
  UpdateEducationGroupSuccess = '[EducationGroup] Updating success!',
  UpdateEducationGroupFailed = '[EducationGroup] Updating failed!',

  DeleteEducationGroup = '[EducationGroup] Deleting education group',
  DeleteEducationGroupSuccess = '[EducationGroup] Deleting success!',
  DeleteEducationGroupFailed = '[EducationGroup] Deleting failed!',
}

export const selectEducationGroup = createAction(EducationGroupActions.SelectEducationGroup, props<{ id: string }>());

export const getEducationGroups = createAction(
  EducationGroupActions.LoadEducationGroups,
  props<{ filter: IEducationGroupFilter }>(),
);

export const getEducationGroupsSuccess = createAction(
  EducationGroupActions.LoadEducationGroupsSuccess,
  props<{ educationGroups: IPaginatedList<IEducationGroup> }>(),
);

export const getEducationGroupsFailed = createAction(EducationGroupActions.LoadEducationGroupsFailed);

export const createEducationGroup = createAction(
  EducationGroupActions.CreateEducationGroup,
  props<{ postEducationGroupModel: ICreateEducationGroup }>(),
);
export const createEducationGroupSuccess = createAction(EducationGroupActions.CreateEducationGroupSuccess);
export const createEducationGroupFailed = createAction(EducationGroupActions.CreateEducationGroupFailed);

export const updateEducationGroup = createAction(
  EducationGroupActions.UpdateEducationGroup,
  props<{ putEducationGroupModel: IUpdateEducationGroup }>(),
);
export const updateEducationGroupSuccess = createAction(EducationGroupActions.UpdateEducationGroupSuccess);
export const updateEducationGroupFailed = createAction(EducationGroupActions.UpdateEducationGroupFailed);

export const deleteEducationGroup = createAction(EducationGroupActions.DeleteEducationGroup, props<{ id: string }>());
export const deleteEducationGroupSuccess = createAction(EducationGroupActions.DeleteEducationGroupSuccess);
export const deleteEducationGroupFailed = createAction(EducationGroupActions.DeleteEducationGroupFailed);
