import { createAction, props } from '@ngrx/store';
import { RoleListResponse } from '../../../types/roles-list-response.model';
import RoleActionTypes from './role.actions.enum';

export const getRoles = createAction(RoleActionTypes.LoadRoles);
export const getRolesSucces = createAction(RoleActionTypes.GetRolesSuccess, props<{ response: RoleListResponse }>());
export const getRolesFailure = createAction(RoleActionTypes.GetRolesError);
export const getRolesForLoggedInUser = createAction(RoleActionTypes.GetRolesForLoggedInUser);
export const getRolesForLoggedInUserSucces = createAction(
  RoleActionTypes.GetRolesForLoggedInUserSuccess,
  props<{ response: string[] }>(),
);
export const getRolesForLoggedInUserFailure = createAction(RoleActionTypes.GetRolesForLoggedInUserFailed);
