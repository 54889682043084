import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { HideLoader, ShowLoader } from '../../../app/common/reducers/actions/ui.actions';
import { State } from '../../../redux/reducers/index.reducer';
import { getRolesFailure, getRolesForLoggedInUserFailure, getRolesForLoggedInUserSucces, getRolesSucces } from '../reducers';
import UserActionTypes from '../reducers/actions/roles/role.actions.enum';
import { RolesService } from '../services/roles.service';

@Injectable({
  providedIn: 'root',
})
export class RoleListEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly rolesService: RolesService,
    private store: Store<State>,
  ) {}

  public loadRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.LoadRoles),
      switchMap(() => this.rolesService.getRoles()),
      map(response => getRolesSucces({ response: response.data })),
      catchError(error => {
        return of(getRolesFailure());
      }),
    );
  });

  public loadRolesForLoggedInUserSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActionTypes.GetRolesForLoggedInUserSuccess),
      map(() => {
        return HideLoader();
      }),
    );
  });
}
