import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  baseUrl = environment.serverApiUrl;

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const apiReq = req.url.startsWith('http') ? req.clone({ url: req.url }) : req.clone({ url: `${this.baseUrl}${req.url}` });
    return next.handle(apiReq);
  }
}
