import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { IPaginatedList, Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as invoiceheaderActions from '../actions/invoice-header.actions';
import { InvoiceHeaderDetailModel, InvoiceHeaderFilter, InvoiceHeaderOverviewModel } from '../types/invoice-header';

export interface InvoiceHeaderState {
  selectedInvoiceHeader: InvoiceHeaderDetailModel;
  invoiceHeaders: IPaginatedList<InvoiceHeaderOverviewModel>;
  isLoading: boolean;
  overview: {
    filter: InvoiceHeaderFilter;
    pagination: Pagination;
  };
}

const initialInvoiceHeaderState: InvoiceHeaderState = {
  selectedInvoiceHeader: null,
  invoiceHeaders: {} as IPaginatedList<InvoiceHeaderOverviewModel>,
  isLoading: false,
  overview: {
    filter: null as InvoiceHeaderFilter,
    pagination: null as Pagination,
  },
};

export const invoiceheaderReducers = createReducer(
  initialInvoiceHeaderState,
  on(
    invoiceheaderActions.getInvoiceHeaders,
    (state, { filter }): InvoiceHeaderState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    invoiceheaderActions.getInvoiceHeadersSuccess,
    (state, { result }): InvoiceHeaderState => ({
      ...state,
      isLoading: false,
      invoiceHeaders: result,
      overview: {
        ...state.overview,
        pagination: extractPagination(result),
      },
    }),
  ),
  on(
    invoiceheaderActions.getInvoiceHeadersFailed,
    (state): InvoiceHeaderState => ({
      ...state,
      isLoading: false,
      invoiceHeaders: {} as IPaginatedList<InvoiceHeaderOverviewModel>,
      overview: {
        pagination: null as Pagination,
        filter: null as InvoiceHeaderFilter,
      },
    }),
  ),
  on(
    invoiceheaderActions.getInvoiceHeader,
    (state, { id }): InvoiceHeaderState => ({
      ...state,
      overview: {
        ...state.overview,
      },
      isLoading: true,
    }),
  ),
  on(
    invoiceheaderActions.getInvoiceHeaderSuccess,
    (state, { result }): InvoiceHeaderState => ({
      ...state,
      isLoading: false,
      selectedInvoiceHeader: result,
    }),
  ),
  on(
    invoiceheaderActions.getInvoiceHeaderFailed,
    (state): InvoiceHeaderState => ({
      ...state,
      isLoading: false,
      selectedInvoiceHeader: {} as InvoiceHeaderDetailModel,
    }),
  ),
);

export const selectInvoiceHeadersState = createSelector(
  (state: State) => state.invoiceHeaders,
  (state: InvoiceHeaderState) => state,
);

export const selectInvoiceHeadersForOverView = createSelector(selectInvoiceHeadersState, (state: InvoiceHeaderState) => {
  return { invoiceHeaders: state.invoiceHeaders };
});

export const selectPaginationForInvoiceHeadersOverview = createSelector(
  selectInvoiceHeadersState,
  invoiceheadersState => invoiceheadersState.overview.pagination,
);

export const selectInvoiceHeader = createSelector(
  selectInvoiceHeadersState,
  invoiceheaderState => invoiceheaderState.selectedInvoiceHeader,
);
