import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import * as CountryTypeActions from '../actions/country-type.actions';
import { CountryType } from '../types/country-types.model';

export interface CountryTypeState {
  countryTypes: CountryType[];
  isLoading: boolean;
}

const initialCountryTypeState: CountryTypeState = {
  countryTypes: [],
  isLoading: false,
};

export const countryTypeReducers = createReducer(
  initialCountryTypeState,
  on(
    CountryTypeActions.getCountryTypes,
    (state): CountryTypeState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    CountryTypeActions.getCountryTypesSuccess,
    (state, { countryTypes }): CountryTypeState => ({
      ...state,
      isLoading: false,
      countryTypes: countryTypes,
    }),
  ),
  on(
    CountryTypeActions.getCountryTypesFailed,
    (state): CountryTypeState => ({
      ...state,
      countryTypes: [],
      isLoading: false,
    }),
  ),
);

export const selectCountryTypesState = createSelector(
  (state: State) => state.countryTypes,
  (state: CountryTypeState) => state,
);

export const selectCountryTypesForOverView = createSelector(
  selectCountryTypesState,
  countryTypeState => countryTypeState.countryTypes,
);
