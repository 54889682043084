import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { IPaginatedList, Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as registrationImportLogActions from '../actions/registration-import-log.actions';
import { IRegistrationImportLog, IRegistrationImportLogFilter } from '../types/registration-import-log.model';

export interface RegistrationImportLogState {
  selectedRegistrationImportLog: IRegistrationImportLog;
  registrationimportlogs: IPaginatedList<IRegistrationImportLog>;
  isLoading: boolean;
  overview: {
    filter: IRegistrationImportLogFilter;
    pagination: Pagination;
  };
}

const initialRegistrationImportLogState: RegistrationImportLogState = {
  selectedRegistrationImportLog: null,
  registrationimportlogs: {} as IPaginatedList<IRegistrationImportLog>,
  isLoading: false,
  overview: {
    filter: null as IRegistrationImportLogFilter,
    pagination: null as Pagination,
  },
};

export const registrationImportLogReducers = createReducer(
  initialRegistrationImportLogState,
  on(
    registrationImportLogActions.getRegistrationImportLogs,
    (state, { filter }): RegistrationImportLogState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    registrationImportLogActions.getRegistrationImportLogsSuccess,
    (state, { result }): RegistrationImportLogState => ({
      ...state,
      isLoading: false,
      registrationimportlogs: result,
      overview: {
        ...state.overview,
        pagination: extractPagination(result),
      },
    }),
  ),
  on(
    registrationImportLogActions.getRegistrationImportLogsFailed,
    (state): RegistrationImportLogState => ({
      ...state,
      isLoading: false,
      registrationimportlogs: {} as IPaginatedList<IRegistrationImportLog>,
      overview: {
        pagination: null as Pagination,
        filter: null as IRegistrationImportLogFilter,
      },
    }),
  ),
);

export const selectRegistrationImportLogsState = createSelector(
  (state: State) => state.registrationImportLogs,
  (state: RegistrationImportLogState) => state,
);

export const selectRegistrationImportLogsForOverView = createSelector(
  selectRegistrationImportLogsState,
  (state: RegistrationImportLogState) => {
    return { registrationimportlogs: state.registrationimportlogs };
  },
);

export const selectPaginationForRegistrationImportLogsOverview = createSelector(
  selectRegistrationImportLogsState,
  registrationimportlogsState => registrationimportlogsState.overview.pagination,
);

export const selectRegistrationImportLog = createSelector(
  selectRegistrationImportLogsState,
  registrationimportlogState => registrationimportlogState.selectedRegistrationImportLog,
);
