import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { IPaginatedList, Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as studentImportLogActions from '../actions/student-import-log.actions';
import { IStudentImportLog, IStudentImportLogFilter } from '../types/student-import-log.model';

export interface StudentImportLogState {
  selectedStudentImportLog: IStudentImportLog;
  studentimportlogs: IPaginatedList<IStudentImportLog>;
  isLoading: boolean;
  overview: {
    filter: IStudentImportLogFilter;
    pagination: Pagination;
  };
}

const initialStudentImportLogState: StudentImportLogState = {
  selectedStudentImportLog: null,
  studentimportlogs: {} as IPaginatedList<IStudentImportLog>,
  isLoading: false,
  overview: {
    filter: null as IStudentImportLogFilter,
    pagination: null as Pagination,
  },
};

export const studentImportLogReducers = createReducer(
  initialStudentImportLogState,
  on(
    studentImportLogActions.getStudentImportLogs,
    (state, { filter }): StudentImportLogState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    studentImportLogActions.getStudentImportLogsSuccess,
    (state, { result }): StudentImportLogState => ({
      ...state,
      isLoading: false,
      studentimportlogs: result,
      overview: {
        ...state.overview,
        pagination: extractPagination(result),
      },
    }),
  ),
  on(
    studentImportLogActions.getStudentImportLogsFailed,
    (state): StudentImportLogState => ({
      ...state,
      isLoading: false,
      studentimportlogs: {} as IPaginatedList<IStudentImportLog>,
      overview: {
        pagination: null as Pagination,
        filter: null as IStudentImportLogFilter,
      },
    }),
  ),
);

export const selectStudentImportLogsState = createSelector(
  (state: State) => state.studentImportLogs,
  (state: StudentImportLogState) => state,
);

export const selectStudentImportLogsForOverView = createSelector(selectStudentImportLogsState, (state: StudentImportLogState) => {
  return { studentimportlogs: state.studentimportlogs };
});

export const selectPaginationForStudentImportLogsOverview = createSelector(
  selectStudentImportLogsState,
  studentimportlogsState => studentimportlogsState.overview.pagination,
);

export const selectStudentImportLog = createSelector(
  selectStudentImportLogsState,
  studentimportlogState => studentimportlogState.selectedStudentImportLog,
);
