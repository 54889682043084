import { createAction, props } from '@ngrx/store';
import { Combination } from '../reducers/combinations.reducer';

export enum CombinationActionTypes {
  AddCombination = '[Combination] Add Combination Node',
  RemoveCombination = '[Combination] Remove Combination Node',
  GetCombinations = '[Combination] Get Combinations',
  GetCombinationsSuccess = '[Combination] Get Combinations success',
  GetCombinationsFailed = '[Combination] Get Combinations failed',
}

export const getCombinations = createAction(CombinationActionTypes.GetCombinations);

export const getCombinationsSuccess = createAction(
  CombinationActionTypes.GetCombinationsSuccess,
  props<{ result: Combination[] }>(),
);

export const getCombinationsFailed = createAction(CombinationActionTypes.GetCombinationsFailed);

export const addCombination = createAction(CombinationActionTypes.AddCombination, props<{ node: Combination }>());

export const removeCombination = createAction(CombinationActionTypes.RemoveCombination, props<{ id: string }>());
