import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { getRolesForLoggedInUserSucces } from '../../../modules/users/reducers';
import { State } from '../../../redux/reducers/index.reducer';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from '../../components/header/header.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './root.container.html',
  styleUrls: ['./root.container.scss'],
  imports: [CommonModule, HeaderComponent, RouterOutlet, BreadcrumbComponent],
})
export class RootContainer implements OnInit {
  constructor(
    private store: Store<State>,
    private authService: MsalService,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(
      getRolesForLoggedInUserSucces({ response: this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles }),
    );
  }
}
