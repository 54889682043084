import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { CreateEducationType, EducationTypeFilter, UpdateEducationType } from '../types/education-types/education-type.model';
import { EducationType } from './../types/education-types/education-type.model';

export enum EducationTypeActions {
  SelectEducationType = '[EducationType] Select education type',

  loadAllEducationTypes = '[EducationType] Load All Education types',
  LoadAllEducationTypesSuccess = '[EducationType] Loading  all education types success!',
  LoadAllEducationTypesFailed = '[EducationType] Loading all education types failed!',

  LoadEducationTypes = '[EducationType] Loading education types',
  LoadEducationTypesSuccess = '[EducationType] Loading education types success!',
  LoadEducationTypesFailed = '[EducationType] Loading education types failed!',

  CreateEducationType = '[EducationType] Create education type',
  CreateEducationTypeSuccess = '[EducationType] Create education type success',
  CreateEducationTypeFailed = '[EducationType] Create education type failed',
  UpdateEducationType = '[EducationType] Update education type',
  UpdateEducationTypeSuccess = '[EducationType] Update education type success',
  UpdateEducationTypeFailed = '[EducationType] Update education type failed',
  DeleteEducationType = '[EducationType] Delete education type',
}

export const selectEducationType = createAction(EducationTypeActions.SelectEducationType, props<{ id: string }>());

export const getEducationTypes = createAction(EducationTypeActions.LoadEducationTypes, props<{ filter: EducationTypeFilter }>());

export const getAllEducationTypesSuccess = createAction(
  EducationTypeActions.LoadAllEducationTypesSuccess,
  props<{ educationTypes: IPaginatedList<EducationType> }>(),
);

export const getAllEducationTypesFailed = createAction(EducationTypeActions.LoadAllEducationTypesFailed);

export const getEducationTypesSuccess = createAction(
  EducationTypeActions.LoadEducationTypesSuccess,
  props<{ educationTypes: IPaginatedList<EducationType> }>(),
);

export const getEducationTypesFailed = createAction(EducationTypeActions.LoadEducationTypesFailed);

export const loadAllEducationTypes = createAction(EducationTypeActions.loadAllEducationTypes);

export const updateEducationType = createAction(
  EducationTypeActions.UpdateEducationType,
  props<{ educationType: UpdateEducationType }>(),
);
export const updateEducationTypeSuccess = createAction(EducationTypeActions.UpdateEducationTypeSuccess);
export const updateEducationTypeFailed = createAction(EducationTypeActions.UpdateEducationTypeFailed);

export const createEducationType = createAction(
  EducationTypeActions.CreateEducationType,
  props<{ educationType: CreateEducationType }>(),
);
export const createEducationTypeSuccess = createAction(EducationTypeActions.CreateEducationTypeSuccess);
export const CreateEducationTypeFailed = createAction(EducationTypeActions.CreateEducationTypeFailed);

export const deleteEducationType = createAction(EducationTypeActions.DeleteEducationType, props<{ id: string }>());
