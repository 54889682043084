import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as AcademicYearActions from '../actions/academic-years.actions';
import { IAcademicYearFilter } from '../types/academic-years/academic-year-filter.model';
import { AcademicYear } from '../types/academic-years/academic-year.model';

export interface AcademicYearState {
  academicYears: AcademicYear[];
  academicYear: AcademicYear;
  isLoading: boolean;
  overview: {
    filter: IAcademicYearFilter;
    pagination: Pagination;
  };
}

const initialAcademicYearState: AcademicYearState = {
  academicYears: [],
  academicYear: null,
  isLoading: false,
  overview: {
    filter: null as IAcademicYearFilter,
    pagination: null as Pagination,
  },
};

export const academicYearReducers = createReducer(
  initialAcademicYearState,
  on(
    AcademicYearActions.getAcademicYears,
    (state, { filter }): AcademicYearState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    AcademicYearActions.getAcademicYearsSuccess,
    (state, { academicYears }): AcademicYearState => ({
      ...state,
      isLoading: false,
      academicYears: academicYears.items,
      overview: {
        ...state.overview,
        pagination: extractPagination(academicYears),
      },
    }),
  ),
  on(
    AcademicYearActions.getAcademicYearsFailed,
    (state): AcademicYearState => ({
      ...state,
      academicYears: [],
      overview: {
        pagination: null as Pagination,
        filter: null as IAcademicYearFilter,
      },
      isLoading: false,
    }),
  ),
  on(
    AcademicYearActions.getAcademicYear,
    (state): AcademicYearState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    AcademicYearActions.getAcademicYearSuccess,
    (state, { academicYear }): AcademicYearState => ({
      ...state,
      isLoading: false,
      academicYear: academicYear,
    }),
  ),
  on(
    AcademicYearActions.getAcademicYearFailed,
    (state): AcademicYearState => ({
      ...state,
      academicYear: null,
      isLoading: false,
    }),
  ),
  on(
    AcademicYearActions.createAcademicYear,
    (state): AcademicYearState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    AcademicYearActions.createAcademicYearSuccess,
    (state, { academicYear }): AcademicYearState => ({
      ...state,
      isLoading: false,
      academicYear: academicYear,
    }),
  ),
  on(
    AcademicYearActions.createAcademicYearFailed,
    (state): AcademicYearState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    AcademicYearActions.updateAcademicYear,
    (state): AcademicYearState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    AcademicYearActions.updateAcademicYearSuccess,
    (state, { academicYear }): AcademicYearState => ({
      ...state,
      isLoading: false,
      academicYear: academicYear,
    }),
  ),
  on(
    AcademicYearActions.updateAcademicYearFailed,
    (state): AcademicYearState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    AcademicYearActions.deleteAcademicYearSuccess,
    (state): AcademicYearState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    AcademicYearActions.deleteAcademicYearFailed,
    (state): AcademicYearState => ({
      ...state,
      isLoading: false,
    }),
  ),
);

export const selectAcademicYearsState = createSelector(
  (state: State) => state.academicYears,
  (state: AcademicYearState) => state,
);

export const selectAcademicYearsForOverView = createSelector(
  selectAcademicYearsState,
  academicYearState => academicYearState.academicYears,
);

export const selectPaginationForAcademicYearsOverview = createSelector(
  selectAcademicYearsState,
  academicYearState => academicYearState.overview.pagination,
);
