import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as PaymentMethodActions from '../actions/payment-method.actions';
import { PaymentMethod, PaymentMethodFilter } from '../types/payment-methods/payment-method.model';

export interface PaymentMethodState {
  paymentMethods: PaymentMethod[];
  overview: {
    filter: PaymentMethodFilter;
    pagination: Pagination;
  };
  isLoading: boolean;
}

const initialPaymentMethodState: PaymentMethodState = {
  paymentMethods: [],
  overview: {
    filter: {} as PaymentMethodFilter,
    pagination: {} as Pagination,
  },
  isLoading: false,
};

export const paymentMethodReducers = createReducer(
  initialPaymentMethodState,
  on(
    PaymentMethodActions.getPaymentMethods,
    (state, { filter }): PaymentMethodState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    PaymentMethodActions.getPaymentMethodsSuccess,
    (state, { paymentMethods }): PaymentMethodState => ({
      ...state,
      isLoading: false,
      paymentMethods: paymentMethods.items,
      overview: {
        ...state.overview,
        pagination: extractPagination(paymentMethods),
      },
    }),
  ),
  on(
    PaymentMethodActions.getPaymentMethodsFailed,
    (state): PaymentMethodState => ({
      ...state,
      paymentMethods: [],
      isLoading: false,
    }),
  ),
);

export const selectPaymentMethodsState = createSelector(
  (state: State) => state.paymentMethods,
  (state: PaymentMethodState) => state,
);

export const selectPaymentMethodsForOverView = createSelector(
  selectPaymentMethodsState,
  paymentMethodState => paymentMethodState.paymentMethods,
);
