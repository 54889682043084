import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { CreateScholarship, Scholarship, ScholarshipFilter, UpdateScholarship } from '../types/scholarships/scholarship.model';

export enum ScholarshipActions {
  LoadScholarships = '[Scholarship] Loading scholarships',
  LoadScholarshipsSuccess = '[Scholarship] Loading success!',
  LoadScholarshipsFailed = '[Scholarship] Loading failed!',
  CreateScholarship = '[Scholarship] Create scholarship',
  CreateScholarshipSuccess = '[Scholarship] Create scholarship success',
  CreateScholarshipFailed = '[Scholarship] Create scholarship failed',
  UpdateScholarship = '[Scholarship] Update scholarship',
  UpdateScholarshipSuccess = '[Scholarship] Update scholarship success',
  UpdateScholarshipFailed = '[Scholarship] Update scholarship failed',
  DeleteScholarship = '[Scholarship] Delete scholarship',
}

export const getScholarships = createAction(ScholarshipActions.LoadScholarships, props<{ filter: ScholarshipFilter }>());

export const getScholarshipsSuccess = createAction(
  ScholarshipActions.LoadScholarshipsSuccess,
  props<{ scholarships: IPaginatedList<Scholarship> }>(),
);

export const getScholarshipsFailed = createAction(ScholarshipActions.LoadScholarshipsFailed);

export const createScholarship = createAction(ScholarshipActions.CreateScholarship, props<{ createModel: CreateScholarship }>());
export const CreateScholarshipSuccess = createAction(ScholarshipActions.CreateScholarshipSuccess);
export const CreateScholarshipFailed = createAction(ScholarshipActions.CreateScholarshipFailed);

export const updateScholarship = createAction(ScholarshipActions.UpdateScholarship, props<{ createModel: UpdateScholarship }>());
export const UpdateScholarshipSuccess = createAction(ScholarshipActions.UpdateScholarshipSuccess);
export const UpdateScholarshipFailed = createAction(ScholarshipActions.UpdateScholarshipFailed);

export const deleteScholarship = createAction(ScholarshipActions.DeleteScholarship, props<{ id: string }>());
