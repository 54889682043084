import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { Subject, filter, startWith, takeUntil } from 'rxjs';
import { getRolesForLoggedInUserSucces, selectRolesForLoggedInUser } from '../../../modules/users/reducers';
import { State } from '../../../redux/reducers/index.reducer';
import { ShowLoader } from '../../common/reducers/actions/ui.actions';
import { AppRoles } from '../../types/app-roles.enum';
import { NavigationItemComponent } from '../navigation-item/navigation-item.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, NavigationItemComponent, MatExpansionModule, MatIconModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private roles: string[];
  public isAdmin = false;
  public isFinance = false;
  public isStudentAdmin = false;
  public isJobStudent = false;
  public isStuvo = false;

  selectedItem?: string;
  showSubNav = false;

  constructor(
    private readonly router: Router,
    private store: Store<State>,
    private authService: MsalService,
  ) {}

  public async handleItemClicked(itemClicked: string): Promise<void> {
    this.selectedItem = itemClicked;
    await this.router.navigate([this.selectedItem]);
  }

  public ngOnInit(): void {
    this.store.dispatch(ShowLoader({ title: 'Applicatie laden' }));
    this.store.dispatch(
      getRolesForLoggedInUserSucces({ response: this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles }),
    );

    this.store.select(selectRolesForLoggedInUser).subscribe(roles => {
      this.roles = roles;

      this.updateRoleFlags();
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.determineSelectedItem());
  }
  public updateRoleFlags() {
    this.isAdmin = this.roles.some(role => role === AppRoles.Admin);
    this.isFinance = this.roles.some(role => role === AppRoles.Finance);
    this.isJobStudent = this.roles.some(role => role === AppRoles.StudentJob);
    this.isStudentAdmin = this.roles.some(role => role === AppRoles.StudentAdministration);
    this.isStuvo = this.roles.some(role => role === AppRoles.StudentAccomodation);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private determineSelectedItem() {
    if (this.router.url.includes('users')) {
      this.selectedItem = 'users';
    }

    if (this.router.url.includes('parameters/education-types')) {
      this.selectedItem = 'parameters/education-types';
    }

    if (this.router.url.includes('parameters/payment-methods')) {
      this.selectedItem = 'parameters/payment-methods';
    }

    if (this.router.url.includes('parameters/educations')) {
      this.selectedItem = 'parameters/educations';
    }

    if (this.router.url.includes('parameters/academic-years')) {
      this.selectedItem = 'parameters/academic-years';
    }

    if (this.router.url.includes('parameters/scholarships')) {
      this.selectedItem = 'parameters/scholarships';
    }

    if (this.router.url.includes('parameters/contracts')) {
      this.selectedItem = 'parameters/contracts';
    }

    if (this.router.url.includes('parameters/country-types')) {
      this.selectedItem = 'parameters/country-types';
    }

    if (this.router.url.includes('parameters/categories')) {
      this.selectedItem = 'parameters/categories';
    }

    if (this.router.url.includes('internationalization/countries')) {
      this.selectedItem = 'internationalization/countries';
    }

    if (this.router.url.includes('students')) {
      this.selectedItem = 'students';
    }

    if (this.router.url.includes('registrations')) {
      this.selectedItem = 'registrations';
    }

    if (this.router.url.includes('invoices')) {
      this.selectedItem = 'invoices';
    }

    if (this.router.url.includes('logs/student-imports')) {
      this.selectedItem = 'logs/student-imports';
    }

    if (this.router.url.includes('logs/registration-imports')) {
      this.selectedItem = 'logs/registration-imports';
    }

    if (this.router.url.includes('combinations')) {
      this.selectedItem = 'combinations';
    }
  }
}
