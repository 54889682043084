import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import * as EducationTypeActions from '../actions/education-type-cumulation.actions';
import { EducationTypeCumulation } from '../types/education-type-cumulations/education-type-cumulation.model';

export interface EducationTypeCumulationState {
  educationTypeCumulations: EducationTypeCumulation[];
  isLoading: boolean;
}

const initialEducationTypeCumulationState: EducationTypeCumulationState = {
  educationTypeCumulations: [],
  isLoading: false,
};

export const educationTypeCumulationReducers = createReducer(
  initialEducationTypeCumulationState,
  on(
    EducationTypeActions.getEducationTypeCumulationsForEducationType,
    (state): EducationTypeCumulationState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    EducationTypeActions.getEducationTypeCumulationsSuccess,
    (state, { educationTypeCumulations }): EducationTypeCumulationState => ({
      ...state,
      isLoading: false,
      educationTypeCumulations: educationTypeCumulations,
    }),
  ),
  on(
    EducationTypeActions.getEducationTypeCumulationsFailed,
    (state): EducationTypeCumulationState => ({
      ...state,
      educationTypeCumulations: [],
      isLoading: false,
    }),
  ),
);

export const selectEducationTypeCumulationsState = createSelector(
  (state: State) => state.educationTypeCumulations,
  (state: EducationTypeCumulationState) => state,
);

export const selectEducationTypeCumulationsForOverView = createSelector(
  selectEducationTypeCumulationsState,
  educationTypeCumulationState => educationTypeCumulationState.educationTypeCumulations,
);
