import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { IAcademicYearFilter } from '../types/academic-years/academic-year-filter.model';
import { AcademicYear } from '../types/academic-years/academic-year.model';
import PostAcademicYearModel from '../types/academic-years/post-academic-year.model';

export enum AcademicYearActions {
  LoadAcademicYears = '[AcademicYear] Loading academic years',
  LoadAcademicYearsSuccess = '[AcademicYear] Loading list success!',
  LoadAcademicYearsFailed = '[AcademicYear] Loading list failed!',

  LoadAcademicYear = '[AcademicYear] Loading academic year',
  LoadAcademicYearSuccess = '[AcademicYear] Loading success!',
  LoadAcademicYearFailed = '[AcademicYear] Loading failed!',

  CreateAcademicYear = '[AcademicYear] Creating academic year',
  CreateAcademicYearSuccess = '[AcademicYear] Creating success!',
  CreateAcademicYearFailed = '[AcademicYear] Creating failed!',

  UpdateAcademicYear = '[AcademicYear] Updating academic year',
  UpdateAcademicYearSuccess = '[AcademicYear] Updating success!',
  UpdateAcademicYearFailed = '[AcademicYear] Updating failed!',

  DeleteAcademicYear = '[AcademicYear] Deleting academic year',
  DeleteAcademicYearSuccess = '[AcademicYear] Deleting success!',
  DeleteAcademicYearFailed = '[AcademicYear] Deleting failed!',
}

export const getAcademicYears = createAction(AcademicYearActions.LoadAcademicYears, props<{ filter: IAcademicYearFilter }>());

export const getAcademicYearsSuccess = createAction(
  AcademicYearActions.LoadAcademicYearsSuccess,
  props<{ academicYears: IPaginatedList<AcademicYear> }>(),
);

export const getAcademicYearsFailed = createAction(AcademicYearActions.LoadAcademicYearsFailed);

export const getAcademicYear = createAction(AcademicYearActions.LoadAcademicYear, props<{ id: string }>());

export const getAcademicYearSuccess = createAction(
  AcademicYearActions.LoadAcademicYearSuccess,
  props<{ academicYear: AcademicYear }>(),
);

export const getAcademicYearFailed = createAction(AcademicYearActions.LoadAcademicYearFailed);

export const createAcademicYear = createAction(
  AcademicYearActions.CreateAcademicYear,
  props<{ postAcademicYearModel: PostAcademicYearModel }>(),
);
export const createAcademicYearSuccess = createAction(
  AcademicYearActions.CreateAcademicYearSuccess,
  props<{ academicYear: AcademicYear }>(),
);
export const createAcademicYearFailed = createAction(AcademicYearActions.CreateAcademicYearFailed);

export const updateAcademicYear = createAction(
  AcademicYearActions.UpdateAcademicYear,
  props<{ id: string; putAcademicYearModel: PostAcademicYearModel }>(),
);
export const updateAcademicYearSuccess = createAction(
  AcademicYearActions.UpdateAcademicYearSuccess,
  props<{ academicYear: AcademicYear }>(),
);
export const updateAcademicYearFailed = createAction(AcademicYearActions.UpdateAcademicYearFailed);

export const deleteAcademicYear = createAction(AcademicYearActions.DeleteAcademicYear, props<{ id: string }>());
export const deleteAcademicYearSuccess = createAction(AcademicYearActions.DeleteAcademicYearSuccess);
export const deleteAcademicYearFailed = createAction(AcademicYearActions.DeleteAcademicYearFailed);
