import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent extends MsalRedirectComponent {
  title = 'student-billing';
}
