import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as EducationGroupActions from '../actions/education-group.actions';
import { IEducationGroup, IEducationGroupFilter } from '../types/education-group.model';

export interface EducationGroupState {
  selectedEducationGroupId: string;
  educationGroups: IEducationGroup[];
  overview: {
    filter: IEducationGroupFilter;
    pagination: Pagination;
  };
  isLoading: boolean;
  isLoadingCumulations: boolean;
}

const initialEducationGroupState: EducationGroupState = {
  selectedEducationGroupId: null,
  educationGroups: [],
  isLoading: false,
  isLoadingCumulations: false,
  overview: {
    filter: {} as IEducationGroupFilter,
    pagination: {} as Pagination,
  },
};

export const educationGroupReducers = createReducer(
  initialEducationGroupState,
  on(
    EducationGroupActions.selectEducationGroup,
    (state, { id }): EducationGroupState => ({
      ...state,
      selectedEducationGroupId: id,
    }),
  ),
  on(
    EducationGroupActions.getEducationGroups,
    (state, { filter }): EducationGroupState => ({
      ...state,
      isLoading: true,
      overview: {
        ...state.overview,
        filter: filter,
      },
    }),
  ),
  on(
    EducationGroupActions.getEducationGroupsSuccess,
    (state, { educationGroups }): EducationGroupState => ({
      ...state,
      isLoading: false,
      educationGroups: educationGroups.items,
      overview: {
        ...state.overview,
        pagination: extractPagination(educationGroups),
      },
    }),
  ),
  on(
    EducationGroupActions.getEducationGroupsFailed,
    (state): EducationGroupState => ({
      ...state,
      educationGroups: [],
      isLoading: false,
    }),
  ),
  on(
    EducationGroupActions.createEducationGroup,
    (state): EducationGroupState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    EducationGroupActions.createEducationGroupSuccess,
    (state): EducationGroupState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    EducationGroupActions.createEducationGroupFailed,
    (state): EducationGroupState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    EducationGroupActions.updateEducationGroup,
    (state): EducationGroupState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    EducationGroupActions.updateEducationGroupSuccess,
    (state): EducationGroupState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    EducationGroupActions.updateEducationGroupFailed,
    (state): EducationGroupState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    EducationGroupActions.deleteEducationGroupSuccess,
    (state): EducationGroupState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    EducationGroupActions.deleteEducationGroupFailed,
    (state): EducationGroupState => ({
      ...state,
      isLoading: false,
    }),
  ),
);

export const selectEducationGroupsState = createSelector(
  (state: State) => state.educationGroups,
  (state: EducationGroupState) => state,
);

export const selectEducationGroupsForOverView = createSelector(
  selectEducationGroupsState,
  educationGroupState => educationGroupState.educationGroups,
);
