import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as studentActions from '../actions/student.actions';
import { IStudentFilter, Student } from '../types/student.model';
export interface StudentState {
  students: { [id: string]: Student };
  detailId: string;
  overview: {
    ids: string[];
    filter: IStudentFilter;
    pagination: Pagination;
    isLoading: boolean;
  };
}

const initialStudentState: StudentState = {
  students: {},
  detailId: null,
  overview: {
    ids: [],
    filter: null as IStudentFilter,
    pagination: null,
    isLoading: false,
  },
};

export const studentReducers = createReducer(
  initialStudentState,
  on(
    studentActions.getStudents,
    (state, { filter }): StudentState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
        isLoading: true,
      },
    }),
  ),
  on(
    studentActions.getStudentsSuccess,
    (state, { response }): StudentState => ({
      ...state,
      students: response.items.reduce(
        (students, student) => ({
          ...students,
          [student.id]: student,
        }),
        { ...state.students },
      ),
      overview: {
        ...state.overview,
        isLoading: false,
        ids: response.items.map(i => i.id),
        pagination: extractPagination(response),
      },
    }),
  ),
  on(
    studentActions.getStudentsFailure,
    (state): StudentState => ({
      ...state,
      students: {},
      overview: {
        ids: [],
        isLoading: false,
        pagination: null as Pagination,
        filter: null as IStudentFilter,
      },
    }),
  ),
  on(
    studentActions.getStudent,
    (state, { id }): StudentState => ({
      ...state,
      detailId: id,
    }),
  ),
  on(
    studentActions.getStudentSuccess,
    (state, { response }): StudentState => ({
      ...state,
      students: {
        ...state.students,
        [response.id]: response,
      },
      overview: {
        ...state.overview,
        ids: state.overview.ids.includes(response.id) ? state.overview.ids : [...state.overview.ids, response.id],
      },
    }),
  ),
  on(
    studentActions.getStudentFailure,
    (state): StudentState => ({
      ...state,
      detailId: null,
    }),
  ),
);

export const selectStudentsState = createSelector(
  (state: State) => state.students,
  (state: StudentState) => state,
);

export const selectStudentsForOverView = createSelector(selectStudentsState, (state: StudentState) => {
  return { students: state.overview.ids.map(s => state.students[s]) ?? [], pagination: state.overview.pagination };
});

export const selectPaginationForStudentsOverview = createSelector(
  selectStudentsState,
  studentsState => studentsState.overview.pagination,
);

export const selectDetailStudent = (id: string) =>
  createSelector(selectStudentsState, studentState => {
    return studentState.students[id];
  });
