import { Sort } from '@angular/material/sort';
import { Pagination } from './paginated.list.model';
import { PagingConstants } from './paging.constant';

export interface IPaginationFilter {
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  orderDirection?: string;
}

export function extractPagination<T extends Pagination>(paginatedList: T): Pagination {
  return {
    currentPage: paginatedList.currentPage,
    pageSize: paginatedList.pageSize,
    count: paginatedList.count,
    totalCount: paginatedList.totalCount,
    hasNext: paginatedList.hasNext,
    hasPrevious: paginatedList.hasPrevious,
  } as Pagination;
}

export function applyOrderByToPaginationFilter<T extends IPaginationFilter>(paginationFilter: T, sort: Sort): T {
  if (!paginationFilter) return paginationFilter;

  paginationFilter = {
    ...paginationFilter,
    orderBy: sort.active,
    orderDirection: sort.direction == 'asc' ? 'ascending' : 'descending',
  };

  return paginationFilter;
}

export function applyPaginationToPaginatorFilter<T extends IPaginationFilter>(paginationFilter: T, paginator: Pagination): T {
  if (!paginationFilter) return paginationFilter;

  paginationFilter = {
    ...paginationFilter,
    pageNumber: paginator?.currentPage ?? 0,
    pageSize: paginator?.pageSize ?? PagingConstants.defaultPageSize,
  };

  return paginationFilter;
}
