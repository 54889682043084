import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-breadcrumb-item',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './breadcrumb-item.component.html',
  styleUrls: ['./breadcrumb-item.component.scss'],
})
export class BreadcrumbItemComponent {
  @Input() public link!: string;
  @Input() public label!: string;
  @Input() public exactUrl = false;
}
