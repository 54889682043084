import * as academicYearReducer from 'src/modules/parameters/reducers/academic-year.reducer';
import * as categoryReducer from 'src/modules/parameters/reducers/category.reducer';
import * as combinationReducer from 'src/modules/combinations/reducers/combinations.reducer';
import * as contractReducer from 'src/modules/parameters/reducers/contract.reducer';
import * as countriesReducers from 'src/modules/internationalization/reducers/country.reducer';
import * as countryTypeReducer from 'src/modules/parameters/reducers/country-type.reducer';
import * as educationGroupReducer from 'src/modules/parameters/reducers/education-group.reducer';
import * as educationReducer from 'src/modules/parameters/reducers/education.reducer';
import * as educationTypeCumulationReducer from 'src/modules/parameters/reducers/education-type-cumulation.reducer';
import * as educationTypeReducer from 'src/modules/parameters/reducers/education-type.reducer';
import * as paymentMethodReducer from 'src/modules/parameters/reducers/payment-method.reducer';
import * as registrationImportLogReducer from 'src/modules/import-logs/reducers/registration-import-log.reducer';
import * as registrationReducer from 'src/modules/registrations/reducers/registration.reducer';
import * as scholarshipReducers from 'src/modules/parameters/reducers/scholarship.reducer';
import * as studentImportLogReducer from 'src/modules/import-logs/reducers/student-import-log.reducer';
import * as studentReducer from 'src/modules/students/reducers/student.reducer';
import * as transactionReducer from 'src/modules/transactions/reducers/transaction.reducer';
import * as userReducer from 'src/modules/users/reducers';
import * as invoiceHeaderReducer from 'src/modules/invoices/reducers/invoice-header.reducer';

export interface State {
  combinations: combinationReducer.CombinationState;
  users: userReducer.UserState;
  roles: userReducer.RoleState;
  accounts: userReducer.AccountState;
  educationTypes: educationTypeReducer.EducationTypeState;
  educationGroups: educationGroupReducer.EducationGroupState;
  educationTypeCumulations: educationTypeCumulationReducer.EducationTypeCumulationState;
  educations: educationReducer.EducationState;
  paymentMethods: paymentMethodReducer.PaymentMethodState;
  academicYears: academicYearReducer.AcademicYearState;
  scholarships: scholarshipReducers.ScholarshipState;
  contracts: contractReducer.ContractState;
  categories: categoryReducer.CategoryState;
  countries: countriesReducers.CountryState;
  countryTypes: countryTypeReducer.CountryTypeState;
  students: studentReducer.StudentState;
  registrations: registrationReducer.RegistrationState;
  transactions: transactionReducer.TransactionState;
  studentImportLogs: studentImportLogReducer.StudentImportLogState;
  registrationImportLogs: registrationImportLogReducer.RegistrationImportLogState;
  invoiceHeaders: invoiceHeaderReducer.InvoiceHeaderState;
}

export const reducers = {
  combinations: combinationReducer.combinationReducers,
  users: userReducer.userReducers,
  roles: userReducer.roleReducers,
  accounts: userReducer.accountReducers,
  paymentMethods: paymentMethodReducer.paymentMethodReducers,
  educationTypes: educationTypeReducer.educationTypeReducers,
  educationTypeCumulations: educationTypeCumulationReducer.educationTypeCumulationReducers,
  educations: educationReducer.educationReducers,
  educationGroups: educationGroupReducer.educationGroupReducers,
  academicYears: academicYearReducer.academicYearReducers,
  scholarships: scholarshipReducers.scholarshipReducers,
  countries: countriesReducers.countryReducers,
  contracts: contractReducer.contractReducers,
  categories: categoryReducer.categoryReducers,
  countryTypes: countryTypeReducer.countryTypeReducers,
  students: studentReducer.studentReducers,
  registrations: registrationReducer.registrationReducers,
  transactions: transactionReducer.transactionReducers,
  studentImportLogs: studentImportLogReducer.studentImportLogReducers,
  registrationImportLogs: registrationImportLogReducer.registrationImportLogReducers,
  invoiceHeaders: invoiceHeaderReducer.invoiceheaderReducers,
};
