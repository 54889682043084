import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map, tap } from 'rxjs';
import { LoaderStyle } from '../../types/loader-styles.enum';
import { closeSnackbar } from '../reducers';
import UIActions from '../reducers/actions/ui.actions.enum';
import { LoaderService } from './../services/loader.service';

@Injectable()
export class UIEffects {
  constructor(
    private actions$: Actions,
    private matSnackBar: MatSnackBar,
    private loaderService: LoaderService,
  ) {}

  public showSnackBar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UIActions.SnackbarOpen),
      map(action => {
        return action as { message: string; action: string; config: MatSnackBarConfig };
      }),
      tap(payload => this.matSnackBar.open(payload.message, payload.action, payload.config)),
      delay(2000),
      map(() => closeSnackbar()),
    );
  });

  public closeSnackbar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UIActions.SnackbarClose),
        map(() => this.matSnackBar.dismiss()),
      );
    },
    { dispatch: false },
  );

  public showLoader$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UIActions.ShowLoader),
        map(action => action as { title: string; loaderStyle: LoaderStyle }),
        tap(action => {
          this.loaderService.open(action.title, action.loaderStyle ?? LoaderStyle.Spinner);
        }),
      );
    },
    { dispatch: false },
  );

  public hideLoader$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UIActions.HideLoader),
        tap(() => {
          this.loaderService.close();
        }),
      );
    },
    { dispatch: false },
  );
}
