<header>
  <div class="main-nav">
    <nav>
      <div class="branding">
        <img src="./../../../../assets/img/logo.png" alt="Karel de grote" class="app-logo" />
      </div>
      <div class="nav-items-container">
        <ul>
          <li
            *ngIf="isAdmin"
            app-navigation-item
            icon="person"
            (itemClicked)="handleItemClicked('users')"
            [isSelectedItem]="selectedItem === 'users'"
          ></li>
          <li
            *ngIf="isAdmin || isStuvo || isJobStudent"
            app-navigation-item
            icon="diversity_3"
            (itemClicked)="handleItemClicked('students')"
            [isSelectedItem]="selectedItem === 'students'"
          ></li>
          <li
            *ngIf="isAdmin || isJobStudent"
            app-navigation-item
            icon="how_to_reg"
            (itemClicked)="handleItemClicked('registrations')"
            [isSelectedItem]="selectedItem === 'registrations'"
          ></li>

          <li
            *ngIf="isAdmin || isStuvo"
            app-navigation-item
            icon="request_page"
            (itemClicked)="handleItemClicked('invoices')"
            [isSelectedItem]="selectedItem === 'invoices'"
          ></li>

          <li
            *ngIf="isAdmin || isStuvo || isJobStudent"
            app-navigation-item
            icon="swap_horiz"
            (itemClicked)="handleItemClicked('transactions')"
            [isSelectedItem]="selectedItem === 'transactions'"
          ></li>

          <li
            *ngIf="isAdmin"
            app-navigation-item
            icon="account_tree"
            (itemClicked)="handleItemClicked('combinations')"
            [isSelectedItem]="selectedItem === 'combinations'"
          ></li>

          <mat-expansion-panel class="mat-elevation-z0" hideToggle *ngIf="isAdmin">
            <mat-expansion-panel-header collapsedHeight="46px" expandedHeight="46px">
              <mat-panel-title>
                <mat-icon>construction</mat-icon>
                <span i18n>Parameters</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <li
                app-navigation-item
                icon="calendar_month"
                (itemClicked)="handleItemClicked('parameters/academic-years')"
                [isSelectedItem]="selectedItem === 'parameters/academic-years'"
              ></li>
              <li
                app-navigation-item
                icon="payments"
                (itemClicked)="handleItemClicked('parameters/payment-methods')"
                [isSelectedItem]="selectedItem === 'parameters/payment-methods'"
              ></li>
              <li
                app-navigation-item
                icon="euro"
                (itemClicked)="handleItemClicked('parameters/scholarships')"
                [isSelectedItem]="selectedItem === 'parameters/scholarships'"
              ></li>
              <li
                app-navigation-item
                icon="description"
                (itemClicked)="handleItemClicked('parameters/contracts')"
                [isSelectedItem]="selectedItem === 'parameters/contracts'"
              ></li>
              <li
                app-navigation-item
                icon="category"
                (itemClicked)="handleItemClicked('parameters/categories')"
                [isSelectedItem]="selectedItem === 'parameters/categories'"
              ></li>
              <li
                app-navigation-item
                icon="school"
                (itemClicked)="handleItemClicked('parameters/educations')"
                [isSelectedItem]="selectedItem === 'parameters/educations'"
              ></li>
              <li
                app-navigation-item
                icon="table_view"
                (itemClicked)="handleItemClicked('parameters/education-groups')"
                [isSelectedItem]="selectedItem === 'parameters/education-groups'"
              ></li>
              <li
                app-navigation-item
                icon="lan"
                (itemClicked)="handleItemClicked('parameters/education-types')"
                [isSelectedItem]="selectedItem === 'parameters/education-types'"
              ></li>
              <li
                app-navigation-item
                icon="flag_circle"
                (itemClicked)="handleItemClicked('parameters/country-types')"
                [isSelectedItem]="selectedItem === 'parameters/country-types'"
              ></li>
            </div>
          </mat-expansion-panel>
           <mat-expansion-panel class="mat-elevation-z0" hideToggle *ngIf="isJobStudent || isStudentAdmin || isAdmin">
            <mat-expansion-panel-header collapsedHeight="46px" expandedHeight="46px">
              <mat-panel-title>
                <mat-icon>receipt_long</mat-icon>
                <span i18n>Logs</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <li
                app-navigation-item
                icon="app_registration"
                (itemClicked)="handleItemClicked('logs/registration-imports')"
                [isSelectedItem]="selectedItem === 'logs/registration-imports'"
              ></li>
              <li
                app-navigation-item
                icon="portrait"
                (itemClicked)="handleItemClicked('logs/student-imports')"
                [isSelectedItem]="selectedItem === 'logs/student-imports'"
              ></li>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0" hideToggle>
            <mat-expansion-panel-header collapsedHeight="46px" expandedHeight="46px">
              <mat-panel-title>
                <mat-icon>language</mat-icon>
                <span i18n>Internationalisatie</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
              <li
                app-navigation-item
                icon="flag"
                (itemClicked)="handleItemClicked('internationalization/countries')"
                [isSelectedItem]="selectedItem === 'internationalization/countries'"
              ></li>
            </div>
          </mat-expansion-panel>
        </ul>
      </div>
    </nav>
  </div>
</header>
