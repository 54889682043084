import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import * as ContractActions from '../actions/contract.actions';
import { Contract, ContractFilter } from '../types/contract.model';
import { extractPagination } from '../../users/types/pagination-filter.model';

export interface ContractState {
  contracts: Contract[];
  overview: {
    filter: ContractFilter;
    pagination: Pagination;
  };
  isLoading: boolean;
}

const initialContractState: ContractState = {
  contracts: [],
  overview: {
    filter: {} as ContractFilter,
    pagination: {} as Pagination,
  },
  isLoading: false,
};

export const contractReducers = createReducer(
  initialContractState,
  on(
    ContractActions.getContracts,
    (state, { filter }): ContractState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    ContractActions.getContractsSuccess,
    (state, { contracts }): ContractState => ({
      ...state,
      isLoading: false,
      contracts: contracts.items,
      overview: {
        ...state.overview,
        pagination: extractPagination(contracts)
      }
    }),
  ),
  on(
    ContractActions.getContractsFailed,
    (state): ContractState => ({
      ...state,
      contracts: [],
      isLoading: false,
    }),
  ),
);

export const selectContractsState = createSelector(
  (state: State) => state.contracts,
  (state: ContractState) => state,
);

export const selectContractsForOverView = createSelector(selectContractsState, contractState => contractState.contracts);
