import { createAction, props } from '@ngrx/store';
import { CountryType, CreateCountryType, UpdateCountryType } from '../types/country-types.model';

export enum CountryTypeActions {
  LoadCountryTypes = '[CountryType] Loading types',
  LoadCountryTypesSuccess = '[CountryType] Loading success!',
  LoadCountryTypesFailed = '[CountryType] Loading failed!',

  CreateCountryType = '[CountryType] Create type',
  CreateCountryTypeSuccess = '[CountryType] Create type success',
  CreateCountryTypeFailed = '[CountryType] Create type failed',
  UpdateCountryType = '[CountryType] Update type',
  UpdateCountryTypeSuccess = '[CountryType] Update type success',
  UpdateCountryTypeFailed = '[CountryType] Update type failed',
  DeleteCountryType = '[CountryType] Delete type',
}

export const getCountryTypes = createAction(CountryTypeActions.LoadCountryTypes);

export const getCountryTypesSuccess = createAction(
  CountryTypeActions.LoadCountryTypesSuccess,
  props<{ countryTypes: CountryType[] }>(),
);

export const getCountryTypesFailed = createAction(CountryTypeActions.LoadCountryTypesFailed);

export const updateCountryType = createAction(CountryTypeActions.UpdateCountryType, props<{ countryType: UpdateCountryType }>());
export const updateCountryTypeSuccess = createAction(CountryTypeActions.UpdateCountryTypeSuccess);
export const updateCountryTypeFailed = createAction(CountryTypeActions.UpdateCountryTypeFailed);

export const createCountryType = createAction(CountryTypeActions.CreateCountryType, props<{ countryType: CreateCountryType }>());
export const createCountryTypeSuccess = createAction(CountryTypeActions.CreateCountryTypeSuccess);
export const CreateCountryTypeFailed = createAction(CountryTypeActions.CreateCountryTypeFailed);

export const deleteCountryType = createAction(CountryTypeActions.DeleteCountryType, props<{ id: string }>());
