export const environment = {
  production: false,
  serverApiUrl: 'https://kdg-student-billing-api-dev.azurewebsites.net',
  azureAd: {
    clientId: 'd6238505-4f91-4ce4-83a6-118efd9226e2',
    authority: 'https://login.microsoftonline.com/85631723-2e3d-4841-8292-db4e64da03fa',
    endpoint: 'https://kdg-student-billing-dev.azurewebsites.net',
  },
  apiConfig: {
    uri: 'https://kdg-student-billing-api-dev.azurewebsites.net',
    scopes: ['api://kdg-api-dev-registration/access_as_user'],
  },
};
