enum UserActionTypes {
  LoadUsers = '[User] USERS_LOAD_STARTED',
  GetUsersSuccess = '[User] USERS_LOAD_SUCCESS',
  GetUsersError = '[User] USERS_LOAD_FAILURE',
  PutUser = '[User] PUT_USER_STARTED',
  PutUserSuccess = '[User] PUT_USER_SUCCESS',
  PutUserError = '[User] PUT_USER_FAILURE',
  PostUser = '[User] POST_USER_STARTED',
  PostUserSuccess = '[User] POST_USER_SUCCESS',
  PostUserError = '[User] POST_USER_FAILURE',
}

export default UserActionTypes;
