import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { CreateEducation, Education, EducationFilter, UpdateEducation } from '../types/educations/education.model';

export enum EducationActions {
  LoadEducations = '[Education] Loading education',
  LoadEducationsSuccess = '[Education] Loading list success!',
  LoadEducationsFailed = '[Education] Loading list failed!',

  LoadEducation = '[Education] Loading education ',
  LoadEducationSuccess = '[Education] Loading success!',
  LoadEducationFailed = '[Education] Loading failed!',

  CreateEducation = '[Education] Creating education ',
  CreateEducationSuccess = '[Education] Creating success!',
  CreateEducationFailed = '[Education] Creating failed!',

  UpdateEducation = '[Education] Updating education ',
  UpdateEducationSuccess = '[Education] Updating success!',
  UpdateEducationFailed = '[Education] Updating failed!',

  DeleteEducation = '[Education] Deleting education ',
  DeleteEducationSuccess = '[Education] Deleting success!',
  DeleteEducationFailed = '[Education] Deleting failed!',
}

export const getEducations = createAction(EducationActions.LoadEducations, props<{ filter: EducationFilter }>());

export const getEducationsSuccess = createAction(
  EducationActions.LoadEducationsSuccess,
  props<{ educations: IPaginatedList<Education> }>(),
);

export const getEducationsFailed = createAction(EducationActions.LoadEducationsFailed);

export const getEducation = createAction(EducationActions.LoadEducation, props<{ id: string }>());

export const getEducationSuccess = createAction(EducationActions.LoadEducationSuccess, props<{ education: Education }>());

export const getEducationFailed = createAction(EducationActions.LoadEducationFailed);

export const createEducation = createAction(EducationActions.CreateEducation, props<{ postEducationModel: CreateEducation }>());
export const createEducationSuccess = createAction(EducationActions.CreateEducationSuccess, props<{ education: Education }>());
export const createEducationFailed = createAction(EducationActions.CreateEducationFailed);

export const updateEducation = createAction(EducationActions.UpdateEducation, props<{ putEducationModel: UpdateEducation }>());
export const updateEducationSuccess = createAction(EducationActions.UpdateEducationSuccess, props<{ education: Education }>());
export const updateEducationFailed = createAction(EducationActions.UpdateEducationFailed);

export const deleteEducation = createAction(EducationActions.DeleteEducation, props<{ id: string }>());
export const deleteEducationSuccess = createAction(EducationActions.DeleteEducationSuccess);
export const deleteEducationFailed = createAction(EducationActions.DeleteEducationFailed);
