import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { IRegistrationFilter, Registration } from '../types/registration';

export enum RegistrationActionTypes {
  GetRegistrations = '[Registration] Get paged list',
  GetRegistrationsSuccess = '[Registration] Get paged success',
  GetRegistrationsFailed = '[Registration] Get paged failed',
  GetRegistrationForStudent = '[Registration] Get for student',
  GetRegistrationForStudentSuccess = '[Registration] Get for student success',
  GetRegistrationForStudentFailed = '[Registration] Get for student failed',
  GetRegistration = '[Registration] Get',
  GetRegistrationSuccess = '[Registration] Get success',
  GetRegistrationFailed = '[Registration] Get failed',
}

export const getRegistrations = createAction(RegistrationActionTypes.GetRegistrations, props<{ filter: IRegistrationFilter }>());

export const getRegistrationsSuccess = createAction(
  RegistrationActionTypes.GetRegistrationsSuccess,
  props<{ result: IPaginatedList<Registration> }>(),
);

export const getRegistrationsFailed = createAction(RegistrationActionTypes.GetRegistrationsFailed);
export const getRegistrationForStudentFailed = createAction(RegistrationActionTypes.GetRegistrationForStudentFailed);
export const getRegistrationForStudentSucces = createAction(
  RegistrationActionTypes.GetRegistrationForStudentSuccess,
  props<{ registration: Registration }>(),
);

export const getRegistrationForStudent = createAction(
  RegistrationActionTypes.GetRegistrationForStudent,
  props<{ studentId: string }>(),
);

export const getRegistrationFailed = createAction(RegistrationActionTypes.GetRegistrationFailed);
export const getRegistrationSuccess = createAction(
  RegistrationActionTypes.GetRegistrationSuccess,
  props<{ registration: Registration }>(),
);

export const getRegistration = createAction(RegistrationActionTypes.GetRegistration, props<{ registrationId: string }>());
