import { createReducer, on } from '@ngrx/store';
import * as snackbarActions from './actions/ui.actions';

export interface SnackbarState {
  show: boolean;
}

const initialSnackbarState: SnackbarState = {
  show: false,
};

export const snackbarReducers = createReducer(
  initialSnackbarState,
  on(
    snackbarActions.openSnackbar,
    (state): SnackbarState => ({
      ...state,
      show: true,
    }),
  ),
  on(
    snackbarActions.openSnackbar,
    (state): SnackbarState => ({
      ...state,
      show: false,
    }),
  ),
);
