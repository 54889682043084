import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppLoaderComponent } from '../../shared/app-loader/app-loader.component';
import { LoaderStyle } from '../../types/loader-styles.enum';

@Injectable({
  providedIn: 'root',
})
export class LoaderService implements OnDestroy {
  public dialogRef: MatDialogRef<AppLoaderComponent> | undefined;
  public isLoading = false;

  constructor(private dialog: MatDialog) {}

  public ngOnDestroy(): void {
    this.dialog.ngOnDestroy();
  }

  public open(title = '', style: LoaderStyle = LoaderStyle.Spinner): void {
    if (!this.isLoading) {
      this.dialogRef = this.dialog.open(AppLoaderComponent, {
        disableClose: true,
        panelClass: 'loader-dialog',
        width: '100vw',
        height: '100vh',
      });

      this.dialogRef.componentInstance.style = style;
      this.dialogRef.componentInstance.title = title;
      this.isLoading = true;
    }
  }

  public close(): void {
    this.isLoading = false;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
