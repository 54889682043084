import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { Contract, ContractFilter, CreateContract, UpdateContract } from '../types/contract.model';

export enum ContractActions {
  LoadContracts = '[Contract] Loading',
  LoadContractsSuccess = '[Contract] Loading success!',
  LoadContractsFailed = '[Contract] Loading failed!',
  CreateContract = '[Contract] Create contract',
  CreateContractSuccess = '[Contract] Create contract success',
  CreateContractFailed = '[Contract] Create contract failed',
  UpdateContract = '[Contract] Update contract',
  UpdateContractSuccess = '[Contract] Update contract success',
  UpdateContractFailed = '[Contract] Update contract failes',
  DeleteContract = '[Contract] Delete contract',
}

export const getContracts = createAction(ContractActions.LoadContracts, props<{ filter: ContractFilter }>());

export const getContractsSuccess = createAction(
  ContractActions.LoadContractsSuccess,
  props<{ contracts: IPaginatedList<Contract> }>(),
);

export const getContractsFailed = createAction(ContractActions.LoadContractsFailed);

export const createContract = createAction(ContractActions.CreateContract, props<{ createModel: CreateContract }>());
export const createContractSuccess = createAction(ContractActions.CreateContractSuccess);
export const createContractFailed = createAction(ContractActions.CreateContractFailed);

export const updateContract = createAction(ContractActions.UpdateContract, props<{ createModel: UpdateContract }>());
export const updateContractSuccess = createAction(ContractActions.UpdateContractSuccess);
export const UpdateContractFailed = createAction(ContractActions.UpdateContract);

export const deleteContract = createAction(ContractActions.DeleteContract, props<{ id: string }>());
