import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from 'src/redux/reducers/index.reducer';

import Role from '../types/role.model';
import * as roleActions from './actions/roles/role.actions';

export interface RoleState {
  roles: Role[];
  loggedInRoles: string[];
  totalRoles: number;
  isLoading: boolean;
}

const initialRoleState: RoleState = {
  roles: [],
  loggedInRoles: [],
  totalRoles: 0,
  isLoading: false,
};

export const roleReducers = createReducer(
  initialRoleState,
  on(
    roleActions.getRoles,
    (state): RoleState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    roleActions.getRolesSucces,
    (state, { response }): RoleState => ({
      ...state,
      isLoading: false,
      roles: [...response.items],
      totalRoles: response.count,
    }),
  ),
  on(
    roleActions.getRolesFailure,
    (state): RoleState => ({
      ...state,
      isLoading: true,
      roles: [],
      totalRoles: 0,
    }),
  ),
  on(
    roleActions.getRolesForLoggedInUser,
    (state): RoleState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    roleActions.getRolesForLoggedInUserSucces,
    (state, { response }): RoleState => ({
      ...state,
      isLoading: false,
      loggedInRoles: response,
    }),
  ),
  on(
    roleActions.getRolesForLoggedInUserFailure,
    (state): RoleState => ({
      ...state,
      isLoading: false,
    }),
  ),
);
export const selectRolesState = createSelector(
  (state: State) => state.roles,
  (state: RoleState) => state,
);

export const selectRoleForOverView = createSelector(selectRolesState, (state: RoleState) => {
  return { role: state.roles };
});

export const selectRolesForLoggedInUser = createSelector(selectRolesState, (state: RoleState) => state.loggedInRoles);

export const selectRolesLoaded = createSelector(selectRolesForLoggedInUser, (roles: string[]) => roles.length > 0);
