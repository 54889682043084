import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../../types/paginated.list.model';
import { PutUserRequest } from '../../../types/put-user-request.model';
import { IUserFilter } from '../../../types/user-filter.model';
import User from '../../../types/user.model';
import UserActionTypes from './user.actions.enum';

export const getUsers = createAction(UserActionTypes.LoadUsers, props<{ filter: IUserFilter }>());
export const getUsersSuccess = createAction(UserActionTypes.GetUsersSuccess, props<{ response: IPaginatedList<User> }>());
export const getUsersFailure = createAction(UserActionTypes.GetUsersError);

export const putUser = createAction(UserActionTypes.PutUser, props<{ userId: string; putUserRequest: PutUserRequest }>());
export const putUserSuccess = createAction(UserActionTypes.PutUserSuccess);
export const putUserFailure = createAction(UserActionTypes.PutUserError);

export const postUser = createAction(UserActionTypes.PostUser, props<{ userId: string }>());
export const postUserSuccess = createAction(UserActionTypes.PostUserSuccess);
export const postUserFailure = createAction(UserActionTypes.PostUserError);
