import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { provideEffects } from '@ngrx/effects';
import { RoleListEffects } from '../modules/users/effects/role-list.effect';
import { MyAuthGuard } from './authentication/auth.guard';
import { RootContainer } from './containers/root/root.container';
import { AppRoles } from './types/app-roles.enum';

const routes: Routes = [
  {
    path: '',
    component: RootContainer,
    providers: [provideEffects([RoleListEffects])],
    data: { breadcrumb: 'Home' },
    canActivate: [MsalGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('../modules/users/users-routing').then(r => r.usersRoutes),
        data: {
          roles: [AppRoles.Admin],
        },
        canActivate: [MyAuthGuard],
      },
      {
        path: 'combinations',
        data: {
          roles: [AppRoles.Admin],
        },
        canActivate: [MyAuthGuard],
        loadChildren: () => import('../modules/combinations/combinations-routing').then(r => r.CombinationsRouting),
      },
      {
        path: 'students',
        loadChildren: () => import('../modules/students/student.routing').then(r => r.studentRoutes),
        data: {
          roles: [AppRoles.Admin, AppRoles.StudentJob, AppRoles.StudentAccomodation],
        },
        canActivate: [MyAuthGuard],
      },
      {
        path: 'registrations',
        loadChildren: () => import('../modules/registrations/registration.routing').then(r => r.RegistrationRoutes),
        data: {
          roles: [AppRoles.Admin, AppRoles.StudentJob, AppRoles.StudentAccomodation],
        },
        canActivate: [MyAuthGuard],
      },
      {
        path: 'transactions',
        loadChildren: () => import('../modules/transactions/transactions.routing').then(r => r.TransactionRoutes),
        data: {
            roles: [AppRoles.Admin, AppRoles.StudentJob, AppRoles.StudentAccomodation],
        },
        canActivate: [MyAuthGuard],
      },
      {
        path: 'invoices',
        loadChildren: () => import('../modules/invoices/invoices.routing').then(r => r.InvoiceHeaderRoutes),
        data: {
            roles: [AppRoles.Admin, AppRoles.StudentJob, AppRoles.StudentAccomodation],
        },
        canActivate: [MyAuthGuard],
      },
      {
        path: 'logs',
        loadChildren: () => import('../modules/import-logs/log.routing').then(r => r.LogRoutes),
      },
      {
        path: 'parameters',
        loadChildren: () => import('../modules/parameters/parameters.routing').then(r => r.parameterRoutes),
        data: {
          roles: [AppRoles.Admin],
        },
        canActivate: [MyAuthGuard],
      },
      {
        path: 'internationalization',
        loadChildren: () =>
          import('../modules/internationalization/internationalization.routing').then(r => r.internationalizationRoutes),
      },
    ],
  },
  {
    path: '401',
    loadComponent: () => import('../app/shared/unauthorized/unauthorized.component').then(c => c.UnauthorizedComponent),
    data: { title: 'Unauthorized' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      // eslint-disable-next-line max-len
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
