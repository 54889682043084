import { createReducer, createSelector, on } from '@ngrx/store';
import * as CategoryActions from 'src/modules/parameters/actions/category.actions';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import { Category, CategoryFilter } from '../types/category.model';

export interface CategoryState {
  categories: Category[];
  overview: {
    filter: CategoryFilter;
    pagination: Pagination;
  };
  isLoading: boolean;
}

const initialCategoryState: CategoryState = {
  categories: [],
  overview: {
    filter: {} as CategoryFilter,
    pagination: {} as Pagination,
  },
  isLoading: false,
};

export const categoryReducers = createReducer(
  initialCategoryState,
  on(
    CategoryActions.getCategories,
    (state, { filter }): CategoryState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    CategoryActions.getCategoriesSuccess,
    (state, { categories }): CategoryState => ({
      ...state,
      isLoading: false,
      categories: categories.items,
      overview: {
        ...state.overview,
        pagination: extractPagination(categories),
      },
    }),
  ),
  on(
    CategoryActions.getCategoriesFailed,
    (state): CategoryState => ({
      ...state,
      categories: [],
      isLoading: false,
    }),
  ),
);

export const selectCategorysState = createSelector(
  (state: State) => state.categories,
  (state: CategoryState) => state,
);

export const selectCategorysForOverView = createSelector(selectCategorysState, categoryState => {
  return { categories: categoryState.categories, pagination: categoryState.overview.pagination };
});
