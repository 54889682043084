import { createAction, props } from '@ngrx/store';
import {
  EducationTypeCumulation,
  EducationTypeCumulationCreateRequestModel,
  EducationTypeCumulationCreateResponseModel,
  EducationTypeCumulationUpdateRequestModel,
  EducationTypeCumulationUpdateResponseModel,
} from '../types/education-type-cumulations/education-type-cumulation.model';

export enum EducationTypeCumulationActions {
  LoadEducationTypeCumulations = '[EducationType] Loading type cumulations',
  LoadEducationTypeCumulationsSuccess = '[EducationType] Loading cumulations success!',
  LoadEducationTypeCumulationsFailed = '[EducationType] Loading cumulations failed!',

  UpdateEducationTypeCumulation = '[EducationType] Update type cumulation',
  UpdateEducationTypeCumulationSuccess = '[EducationType] Update type cumulation success',
  UpdateEducationTypeCumulationFailed = '[EducationType] Update type cumulation failed',

  CreateEducationTypeCumulation = '[EducationType] Create type cumulation',
  CreateEducationTypeCumulationSuccess = '[EducationType] Create type cumulation success',
  CreateEducationTypeCumulationFailed = '[EducationType] Create type cumulation failed',

  DeleteEducationTypeCumulation = '[EducationType] Delete type cumulation',
  DeleteEducationTypeCumulationSuccess = '[EducationType] Delete type cumulation success',
  DeleteEducationTypeCumulationFailed = '[EducationType] Delete type cumulation failed',
}

export const getEducationTypeCumulationsForEducationType = createAction(
  EducationTypeCumulationActions.LoadEducationTypeCumulations,
  props<{ educationTypeId: string }>(),
);

export const getEducationTypeCumulationsSuccess = createAction(
  EducationTypeCumulationActions.LoadEducationTypeCumulationsSuccess,
  props<{ educationTypeCumulations: EducationTypeCumulation[] }>(),
);
export const getEducationTypeCumulationsFailed = createAction(
  EducationTypeCumulationActions.LoadEducationTypeCumulationsFailed,
  props<{ errorCode: any }>(),
);

export const createEducationTypeCumulation = createAction(
  EducationTypeCumulationActions.CreateEducationTypeCumulation,
  props<{ educationTypeId: string; createModel: EducationTypeCumulationCreateRequestModel }>(),
);

export const createEducationTypeCumulationSuccess = createAction(
  EducationTypeCumulationActions.CreateEducationTypeCumulationSuccess,
  props<{ educationTypeCumulationCreateResponseModel: EducationTypeCumulationCreateResponseModel }>(),
);

export const createEducationTypeCumulationFailed = createAction(
  EducationTypeCumulationActions.CreateEducationTypeCumulationFailed,
  props<{ errorCode: any }>(),
);

export const updateEducationTypeCumulation = createAction(
  EducationTypeCumulationActions.UpdateEducationTypeCumulation,
  props<{ educationTypeId: string; cumulationId: string; updateModel: EducationTypeCumulationUpdateRequestModel }>(),
);

export const updateEducationTypeCumulationSuccess = createAction(
  EducationTypeCumulationActions.UpdateEducationTypeCumulationSuccess,
  props<{ educationTypeCumulationCreateResponseModel: EducationTypeCumulationUpdateResponseModel }>(),
);

export const updateEducationTypeCumulationFailed = createAction(
  EducationTypeCumulationActions.UpdateEducationTypeCumulationFailed,
  props<{ errorCode: any }>(),
);

export const deleteEducationTypeCumulation = createAction(
  EducationTypeCumulationActions.DeleteEducationTypeCumulation,
  props<{ educationTypeId: string; cumulationId: string }>(),
);

export const deleteEducationTypeCumulationSuccess = createAction(
  EducationTypeCumulationActions.DeleteEducationTypeCumulationSuccess,
);
export const deleteEducationTypeCumulationFailed = createAction(
  EducationTypeCumulationActions.DeleteEducationTypeCumulationFailed,
  props<{ errorCode: any }>(),
);
