import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as EducationTypeActions from '../actions/education-type.actions';
import { EducationType, EducationTypeFilter } from '../types/education-types/education-type.model';

export interface EducationTypeState {
  selectedEducationTypeId: string;
  educationTypes: { [id: string]: EducationType };
  isLoading: boolean;
  overview: {
    ids: string[];
    filter: EducationTypeFilter;
    pagination: Pagination;
  };
  isLoadingCumulations: boolean;
}

const initialEducationTypeState: EducationTypeState = {
  selectedEducationTypeId: null,
  educationTypes: {},
  overview: {
    ids: [],
    filter: {} as EducationTypeFilter,
    pagination: {} as Pagination,
  },
  isLoading: false,
  isLoadingCumulations: false,
};

export const educationTypeReducers = createReducer(
  initialEducationTypeState,
  on(
    EducationTypeActions.selectEducationType,
    (state, { id }): EducationTypeState => ({
      ...state,
      selectedEducationTypeId: id,
    }),
  ),
  on(
    EducationTypeActions.getEducationTypes,
    (state, { filter }): EducationTypeState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    EducationTypeActions.getAllEducationTypesSuccess,
    (state, { educationTypes }): EducationTypeState => ({
      ...state,
      isLoading: false,
      educationTypes: educationTypes.items.reduce(
        (educations, education) => ({
          ...educations,
          [education.id]: education,
        }),
        { ...state.educationTypes },
      ),
      overview: {
        ...state.overview,
      },
    }),
  ),
  on(
    EducationTypeActions.getAllEducationTypesFailed,
    (state): EducationTypeState => ({
      ...state,
      overview: {
        ...state.overview,
        ids: [],
      },
      isLoading: false,
    }),
  ),
  on(
    EducationTypeActions.getEducationTypesSuccess,
    (state, { educationTypes }): EducationTypeState => ({
      ...state,
      isLoading: false,
      educationTypes: educationTypes.items.reduce(
        (educations, education) => ({
          ...educations,
          [education.id]: education,
        }),
        { ...state.educationTypes },
      ),
      overview: {
        ...state.overview,
        ids: educationTypes.items.map(e => e.id),
        pagination: extractPagination(educationTypes),
      },
    }),
  ),
  on(
    EducationTypeActions.getEducationTypesFailed,
    (state): EducationTypeState => ({
      ...state,
      overview: {
        ...state.overview,
        ids: [],
      },
      isLoading: false,
    }),
  ),
);

export const selectEducationTypesState = createSelector(
  (state: State) => state.educationTypes,
  (state: EducationTypeState) => state,
);

export const selectEducationTypesForOverView = createSelector(selectEducationTypesState, educationTypeState => {
  return { educationTypes: educationTypeState.overview.ids.map(e => educationTypeState.educationTypes[e]) };
});

export const selectAllEducationTypes = createSelector(selectEducationTypesState, es => {
  return { educationTypes: Object.values(es.educationTypes) };
});
