import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { InvoiceHeaderDetailModel, InvoiceHeaderFilter, InvoiceHeaderOverviewModel } from '../types/invoice-header';

export enum InvoiceHeaderActionTypes {
  GetInvoiceHeaders = '[InvoiceHeader] Get paged list',
  GetInvoiceHeadersSuccess = '[InvoiceHeader] Get paged success',
  GetInvoiceHeadersFailed = '[InvoiceHeader] Get paged failed',
  GetInvoiceHeader = '[InvoiceHeader] Get',
  GetInvoiceHeaderSuccess = '[InvoiceHeader] Get success',
  GetInvoiceHeaderFailed = '[InvoiceHeader] Get failed',
  ImportExcelInvoices = '[ExcelInvoices] Import Excel Invoices',
  ImportExcelInvoicesSuccess = '[ExcelInvoices] Import Excel Invoices success',
  ImportExcelInvoicesFailed = '[ExcelInvoices] Import Excel Invoices failed',
}

export const getInvoiceHeaders = createAction(
  InvoiceHeaderActionTypes.GetInvoiceHeaders,
  props<{ filter: InvoiceHeaderFilter }>(),
);

export const getInvoiceHeadersSuccess = createAction(
  InvoiceHeaderActionTypes.GetInvoiceHeadersSuccess,
  props<{ result: IPaginatedList<InvoiceHeaderOverviewModel> }>(),
);

export const getInvoiceHeadersFailed = createAction(InvoiceHeaderActionTypes.GetInvoiceHeadersFailed);

export const getInvoiceHeader = createAction(InvoiceHeaderActionTypes.GetInvoiceHeader, props<{ id: string }>());

export const getInvoiceHeaderSuccess = createAction(
  InvoiceHeaderActionTypes.GetInvoiceHeaderSuccess,
  props<{ result: InvoiceHeaderDetailModel }>(),
);

export const getInvoiceHeaderFailed = createAction(InvoiceHeaderActionTypes.GetInvoiceHeaderFailed);

export const importExcelInvoices = createAction(InvoiceHeaderActionTypes.ImportExcelInvoices, props<{ file: File }>());

export const importExcelInvoicesSuccess = createAction(InvoiceHeaderActionTypes.ImportExcelInvoicesSuccess);

export const importExcelInvoicesFailed = createAction(InvoiceHeaderActionTypes.ImportExcelInvoicesFailed);
