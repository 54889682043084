import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import GenericResponseModel from '../../../app/types/generic-response.model';
import { RoleListResponse } from '../types/roles-list-response.model';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private readonly http: HttpClient) {}

  public getRoles(): Observable<GenericResponseModel<RoleListResponse>> {
    return this.http.get<GenericResponseModel<RoleListResponse>>('/api/roles');
  }
}
