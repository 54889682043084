import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: '[app-navigation-item]',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent implements OnInit {
  @HostBinding('class.-active') public get valid() {
    return this.isSelectedItem;
  }
  title?: string;
  @Input() icon?: string;
  @Input() isSelectedItem = false;

  @Output() itemClicked = new EventEmitter<void>();
  public ngOnInit(): void {
    const iconToTitleMap = {
      person: 'Gebruikersbeheer',
      school: 'Opleidingen',
      payments: 'Betalingswijzen',
      calendar_month: 'Academiejaren',
      description: 'Contracten',
      lan: 'Opleidingstypes',
      flag: 'Landen',
      euro: 'Beurzen',
      request_page: 'Facturen',
      category: 'Categorieën',
      flag_circle: 'Landstypes',
      diversity_3: 'Studenten',
      table_view: 'Opleidingsgroepen',
      how_to_reg: 'Registraties',
      swap_horiz: 'Transacties',
      portrait: 'Studenten',
      app_registration: 'Registraties',
      account_tree: 'Combinaties',
    };

    this.title = iconToTitleMap[this.icon] || '';
  }

  public handleItemClicked(): void {
    this.itemClicked.emit();
  }
}
