import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderStyle } from '../../types/loader-styles.enum';

@Component({
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatProgressSpinnerModule, MatProgressBarModule],
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
})
export class AppLoaderComponent {
  public style: LoaderStyle = LoaderStyle.Spinner;
  public title: string | undefined;
  public message: string | undefined;

  constructor(public dialogRef: MatDialogRef<AppLoaderComponent>) {}

  public useLine(): boolean {
    return this.style === LoaderStyle.Line;
  }

  public useSpinner(): boolean {
    return this.style === LoaderStyle.Spinner;
  }
}
