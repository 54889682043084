import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as CountryActions from '../actions/country.actions';
import ICountryFilter from '../types/country-filter.model';
import Country from '../types/country.model';

export interface CountryState {
  countries: Country[];
  isLoading: boolean;
  overview: {
    filter: ICountryFilter;
    pagination: Pagination;
  };
}

const initialCountryState: CountryState = {
  countries: [],
  isLoading: false,
  overview: {
    filter: null as ICountryFilter,
    pagination: null as Pagination,
  },
};

export const countryReducers = createReducer(
  initialCountryState,
  on(
    CountryActions.getCountries,
    (state, { filter }): CountryState => ({
      ...state,
      isLoading: true,
      overview: {
        ...state.overview,
        filter: filter,
      },
    }),
  ),
  on(
    CountryActions.getCountriesSuccess,
    (state, { countries }): CountryState => ({
      ...state,
      isLoading: false,
      countries: countries.items,
      overview: {
        ...state.overview,
        pagination: extractPagination(countries),
      },
    }),
  ),
  on(
    CountryActions.getCountriesFailed,
    (state): CountryState => ({
      ...state,
      countries: [],
      overview: {
        pagination: null as Pagination,
        filter: null as ICountryFilter,
      },
      isLoading: false,
    }),
  ),
);

export const selectCountriesState = createSelector(
  (state: State) => state.countries,
  (state: CountryState) => state,
);

export const selectCountriesForOverView = createSelector(selectCountriesState, countryState => countryState.countries);
