import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import { Category, CategoryFilter, UpdateCategory } from '../types/category.model';
import { CreateCategory } from './../types/category.model';

export enum CategoryActions {
  LoadCategories = '[Category] Loading types',
  LoadCategoriesSuccess = '[Category] Loading success!',
  LoadCategoriesFailed = '[Category] Loading failed!',

  CreateCategory = '[Category] Create category',
  CreateCategorySuccess = '[Category] Create category success',
  CreateCategoryFailed = '[Category] Create category failed',
  UpdateCategory = '[Category] Update Category',
  UpdateCategorySuccess = '[Category] Update Category Success',
  UpdateCategoryFailed = '[Category] Update Category Failed',
  DeleteCategory = '[Category] Delete category',
}

export const getCategories = createAction(CategoryActions.LoadCategories, props<{ filter: CategoryFilter }>());

export const getCategoriesSuccess = createAction(
  CategoryActions.LoadCategoriesSuccess,
  props<{ categories: IPaginatedList<Category> }>(),
);

export const getCategoriesFailed = createAction(CategoryActions.LoadCategoriesFailed);

export const createCategory = createAction(CategoryActions.CreateCategory, props<{ createModel: CreateCategory }>());
export const createCategorySuccess = createAction(CategoryActions.CreateCategorySuccess);
export const createCategoryFailed = createAction(CategoryActions.CreateCategoryFailed);

export const updateCategory = createAction(CategoryActions.UpdateCategory, props<{ createModel: UpdateCategory }>());
export const updateCategorySuccess = createAction(CategoryActions.UpdateCategorySuccess);
export const updateCategoryFailed = createAction(CategoryActions.UpdateCategoryFailed);

export const deleteCategory = createAction(CategoryActions.DeleteCategory, props<{ id: string }>());
