import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import {
  CreatePaymentMethod,
  PaymentMethod,
  PaymentMethodFilter,
  UpdatePaymentMethod,
} from '../types/payment-methods/payment-method.model';

export enum PaymentMethodActions {
  LoadPaymentMethods = '[PaymentMethod] Loading payment methods',
  LoadPaymentMethodsSuccess = '[PaymentMethod] Loading success!',
  LoadPaymentMethodsFailed = '[PaymentMethod] Loading failed!',

  CreatePaymentMethod = '[PaymentMethod] Create type',
  CreatePaymentMethodSuccess = '[PaymentMethod] Create type success',
  CreatePaymentMethodFailed = '[PaymentMethod] Create type failed',
  UpdatePaymentMethod = '[PaymentMethod] Update type',
  UpdatePaymentMethodSuccess = '[PaymentMethod] Update type success',
  UpdatePaymentMethodFailed = '[PaymentMethod] Update type failed',
  DeletePaymentMethod = '[PaymentMethod] Delete type',
}

export const getPaymentMethods = createAction(PaymentMethodActions.LoadPaymentMethods, props<{ filter: PaymentMethodFilter }>());

export const getPaymentMethodsSuccess = createAction(
  PaymentMethodActions.LoadPaymentMethodsSuccess,
  props<{ paymentMethods: IPaginatedList<PaymentMethod> }>(),
);

export const getPaymentMethodsFailed = createAction(PaymentMethodActions.LoadPaymentMethodsFailed);

export const updatePaymentMethod = createAction(
  PaymentMethodActions.UpdatePaymentMethod,
  props<{ paymentMethod: UpdatePaymentMethod }>(),
);
export const UpdatePaymentMethodSuccess = createAction(PaymentMethodActions.UpdatePaymentMethodSuccess);
export const UpdatePaymentMethodFailed = createAction(PaymentMethodActions.UpdatePaymentMethodFailed);

export const createPaymentMethod = createAction(
  PaymentMethodActions.CreatePaymentMethod,
  props<{ paymentMethod: CreatePaymentMethod }>(),
);
export const CreatePaymentMethodSuccess = createAction(PaymentMethodActions.CreatePaymentMethodSuccess);
export const CreatePaymentMethodFailed = createAction(PaymentMethodActions.CreatePaymentMethodFailed);

export const deletePaymentMethod = createAction(PaymentMethodActions.DeletePaymentMethod, props<{ id: string }>());
