import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from 'src/redux/reducers/index.reducer';
import Account from '../types/account.model';
import * as accountActions from './actions/accounts/account.actions';

export interface AccountState {
  accounts: Account[];
  totalAccounts: number;
  isLoading: boolean;
}

const initialUserState: AccountState = {
  accounts: [],
  totalAccounts: 0,
  isLoading: false,
};

export const accountReducers = createReducer(
  initialUserState,
  on(
    accountActions.getAccounts,
    (state): AccountState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    accountActions.getAccountsSuccess,
    (state, { response }): AccountState => ({
      ...state,
      isLoading: false,
      accounts: [...response.items],
      totalAccounts: response.count,
    }),
  ),
  on(
    accountActions.getAccountsFailure,
    (state): AccountState => ({
      ...state,
      isLoading: true,
      accounts: [],
      totalAccounts: 0,
    }),
  ),
);

export const selectAccountsState = createSelector(
  (state: State) => state.accounts,
  (state: AccountState) => state,
);
