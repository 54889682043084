import { createAction, props } from '@ngrx/store';
import { IPaginatedList } from '../../users/types/paginated.list.model';
import ICountryFilter from '../types/country-filter.model';
import Country from '../types/country.model';
import PostCountryModel from '../types/post-country.model';
import PutCountryModel from '../types/put-country.model';

export enum CountryActions {
  LoadCountries = '[Country] Loading countries',
  LoadCountriesSuccess = '[Country] Loading success!',
  LoadCountriesFailed = '[Country] Loading failed!',

  CreateCountry = '[Country] Creating country',
  CreateCountrySuccess = '[Country] Creating success!',
  CreateCountryFailed = '[Country] Creating failed!',

  UpdateCountry = '[Country] Updating country',
  UpdateCountrySuccess = '[Country] Updating success!',
  UpdateCountryFailed = '[Country] Updating failed!',

  DeleteCountry = '[Country] Deleting country',
  DeleteCountrySuccess = '[Country] Deleting success!',
  DeleteCountryFailed = '[Country] Deleting failed!',
}

export const getCountries = createAction(CountryActions.LoadCountries, props<{ filter: ICountryFilter }>());

export const getCountriesSuccess = createAction(
  CountryActions.LoadCountriesSuccess,
  props<{ countries: IPaginatedList<Country> }>(),
);

export const getCountriesFailed = createAction(CountryActions.LoadCountriesFailed);

export const createCountry = createAction(CountryActions.CreateCountry, props<{ postCountryModel: PostCountryModel }>());
export const createCountrySuccess = createAction(CountryActions.CreateCountrySuccess, props<{ country: Country }>());
export const createCountryFailed = createAction(CountryActions.CreateCountryFailed);

export const updateCountry = createAction(
  CountryActions.UpdateCountry,
  props<{ id: string; putCountryModel: PutCountryModel }>(),
);
export const updateCountrySuccess = createAction(CountryActions.UpdateCountrySuccess, props<{ country: Country }>());
export const updateCountryFailed = createAction(CountryActions.UpdateCountryFailed);

export const deleteCountry = createAction(CountryActions.DeleteCountry, props<{ id: string }>());
export const deleteCountrySuccess = createAction(CountryActions.DeleteCountrySuccess);
export const deleteCountryFailed = createAction(CountryActions.DeleteCountryFailed);
