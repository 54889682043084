enum RoleActionTypes {
  LoadRoles = '[User] ROLES_LOAD_STARTED',
  GetRolesSuccess = '[User] ROLES_LOAD_SUCCESS',
  GetRolesError = '[User] ROLES_LOAD_FAILURE',

  GetRolesForLoggedInUser = '[User] Get Roles for logged in ',
  GetRolesForLoggedInUserSuccess = '[User] Get Roles for logged in success',
  GetRolesForLoggedInUserFailed = '[User] Get Roles for logged in failed',
}

export default RoleActionTypes;
