import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as ScholarshipActions from '../actions/scholarship.actions';
import { Scholarship, ScholarshipFilter } from '../types/scholarships/scholarship.model';

export interface ScholarshipState {
  scholarships: Scholarship[];
  overview: {
    filter: ScholarshipFilter;
    pagination: Pagination;
  };
  isLoading: boolean;
}

const initialScholarshipState: ScholarshipState = {
  scholarships: [],
  overview: {
    filter: {} as ScholarshipFilter,
    pagination: {} as Pagination,
  },
  isLoading: false,
};

export const scholarshipReducers = createReducer(
  initialScholarshipState,
  on(
    ScholarshipActions.getScholarships,
    (state, { filter }): ScholarshipState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    ScholarshipActions.getScholarshipsSuccess,
    (state, { scholarships }): ScholarshipState => ({
      ...state,
      isLoading: false,
      scholarships: scholarships.items,
      overview: {
        ...state.overview,
        pagination: extractPagination(scholarships),
      },
    }),
  ),
  on(
    ScholarshipActions.getScholarshipsFailed,
    (state): ScholarshipState => ({
      ...state,
      isLoading: false,
    }),
  ),
);

export const selectScholarshipsState = createSelector(
  (state: State) => state.scholarships,
  (state: ScholarshipState) => state,
);

export const selectScholarshipsForOverView = createSelector(
  selectScholarshipsState,
  scholarshipState => scholarshipState.scholarships,
);
