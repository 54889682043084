import { createReducer, createSelector, on } from '@ngrx/store';
import { State } from '../../../redux/reducers/index.reducer';
import { IPaginatedList, Pagination } from '../../users/types/paginated.list.model';
import { extractPagination } from '../../users/types/pagination-filter.model';
import * as registrationActions from '../actions/registration.actions';
import { IRegistrationFilter, Registration } from '../types/registration';

export interface RegistrationState {
  selectedRegistration: Registration;
  registrations: IPaginatedList<Registration>;
  isLoading: boolean;
  overview: {
    filter: IRegistrationFilter;
    pagination: Pagination;
  };
}

const initialRegistrationState: RegistrationState = {
  selectedRegistration: null,
  registrations: {} as IPaginatedList<Registration>,
  isLoading: false,
  overview: {
    filter: null as IRegistrationFilter,
    pagination: null as Pagination,
  },
};

export const registrationReducers = createReducer(
  initialRegistrationState,
  on(
    registrationActions.getRegistrations,
    (state, { filter }): RegistrationState => ({
      ...state,
      overview: {
        ...state.overview,
        filter: filter,
      },
      isLoading: true,
    }),
  ),
  on(
    registrationActions.getRegistrationsSuccess,
    (state, { result }): RegistrationState => ({
      ...state,
      isLoading: false,
      registrations: result,
      overview: {
        ...state.overview,
        pagination: extractPagination(result),
      },
    }),
  ),
  on(
    registrationActions.getRegistrationsFailed,
    (state): RegistrationState => ({
      ...state,
      isLoading: false,
      registrations: {} as IPaginatedList<Registration>,
      overview: {
        pagination: null as Pagination,
        filter: null as IRegistrationFilter,
      },
    }),
  ),
  on(
    registrationActions.getRegistrationForStudent,
    (state, { studentId }): RegistrationState => ({
      ...state,
      selectedRegistration: null,
      isLoading: true,
    }),
  ),
  on(
    registrationActions.getRegistrationForStudentSucces,
    (state, { registration }): RegistrationState => ({
      ...state,
      selectedRegistration: registration,
    }),
  ),
  on(
    registrationActions.getRegistrationForStudentFailed,
    (state): RegistrationState => ({
      ...state,
      selectedRegistration: null,
    }),
  ),
  on(
    registrationActions.getRegistration,
    (state, { registrationId }): RegistrationState => ({
      ...state,
      selectedRegistration: null,
    }),
  ),
  on(
    registrationActions.getRegistrationSuccess,
    (state, { registration }): RegistrationState => ({
      ...state,
      selectedRegistration: registration,
    }),
  ),
  on(
    registrationActions.getRegistrationFailed,
    (state): RegistrationState => ({
      ...state,
      selectedRegistration: null,
    }),
  ),
);

export const selectRegistrationsState = createSelector(
  (state: State) => state.registrations,
  (state: RegistrationState) => state,
);

export const selectRegistrationsForOverView = createSelector(selectRegistrationsState, (state: RegistrationState) => {
  return { registrations: state.registrations };
});

export const selectPaginationForRegistrationsOverview = createSelector(
  selectRegistrationsState,
  registrationsState => registrationsState.overview.pagination,
);

export const selectRegistration = createSelector(
  selectRegistrationsState,
  registrationState => registrationState.selectedRegistration,
);
